.rental-detail-page {
    .page-title-bar {
        @include page-title-bar;
    }
    .section-title {
        @include section-title;
    }
    .single-info-holder {
        @include single-info-holder;
    }
    .bike-template-wrapper {
        .location-box {
            figure {
                height: 120px;
            }
            .infos-holder {
                padding: 20px 220px 20px 20px;
                .details {
                    .hours {
                        margin-right: 40px;
                    }
                }
            }
        }
    }
    .bike-template {
        .entry-detail-slider {
            .slick-slide {
                a {
                    text-align: center;
                    img {
                        height: 400px;
                        width: auto;
                        max-width: initial;
                    }
                }
            }
        }
    }

    .sidebar-wrapper {
        transition: all ease 0.3s;
        &.sticked {
            position: fixed;
            top: 20px;
            z-index: 8;
            bottom: 0;
            overflow-y: auto;
            overflow-x: hidden;
            @include scrollbar;
            transition: all ease 0.3s;
            .sidebar {
                margin-bottom: 15px;
            }
        }
        .sidebar {
            @include booking-sidebar;
        }
    }
    section.duration {
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 30px;
    }
    section.faq {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    section.bike-prices {
        margin-bottom: 30px;
    }
    section.locations {
        background: black;
        margin-bottom: 30px;
        padding: 15px 30px;
        @include media("<tablet") {
            margin-left: -15px;
            margin-right: -15px;
        }
        h2 {
            color: #fff;
        }
        .arrow {
            width: 40px;
            height: 40px;
            &.prev {
                left: -20px;
            }
            &.next {
                right: -20px;
            }
        }
        .slick-dots {
            margin: 15px 0 0;
        }
    }
    .bottom-rental-bar {
        background-color: $black;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        padding: 30px 0;
        transition: all ease 0.3s;
        transform: translateY(200px);
        &.active {
            transform: translateY(0);
        }
        .holder {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h4 {
                font-weight: 500;
                font-size: 28px;
                color: #ffffff;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                span {
                    display: block;
                    color: $green;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            .prices {
                display: inline-flex;
                align-items: center;
                color: #fff;
                white-space: nowrap;
                .price {
                    font-size: 18px;
                    font-weight: 500;
                    margin-right: 20px;
                    b {
                        font-size: 30px;
                    }
                }
            }
        }
    }
    .city-banner {
        margin-bottom: 30px;
        img {
            width: 100%;
            height: auto;
        }
    }
    @include media("<=mobile-landscape") {
        .bottom-rental-bar {
            padding: 12px 12px 12px 18px;
            .container {
                padding: 0;
            }
            .holder {
                h4 {
                    font-size: 0;
                    position: absolute;
                    max-width: 40%;
                    span {
                        font-size: 10px;
                        line-height: 11px;
                    }
                }
                .prices {
                    width: 100%;
                    justify-content: space-between;
                    .price {
                        font-size: 12px;
                        transform: translateY(-10px);
                        b {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .content {
            font-size: 12px;
            line-height: 14px;
        }
        .single-info-holder {
            @include single-info-holder-mobile;
        }
        .similar-rentals {
            .grid {
                @include mobile-half-grid;
            }
        }
        .bike-template {
            & > .grid {
                @include mobile-full-grid;
            }
            .sidebar-wrapper {
                display: none;
            }
        }
        .bike-template-wrapper {
            .location-box {
                .infos-holder {
                    padding: 18px 18px 60px 18px;
                }
            }
        }
    }
}

@include media(">=desktop") {
    .rental-detail-page {
        .mobile-full-grid {
            .col-6 {
                padding-left: 0
            }
        }
    }
}

