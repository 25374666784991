.affiliate-page {
    .page-title-bar {
        position: relative;
        padding: 130px 0;
        background-size: cover;
        background-position: center center;
        margin-bottom: 30px;
        h1 {
            font-size: 36px;
            text-transform: none;
            color: #fff;
            margin: 0;
            text-align: center;
        }
        &:after {
            background: url("#{$images-path}title-divider.svg") bottom left no-repeat;
            background-size: contain;
            content: "";
            position: absolute;
            left: 27px;
            right: 27px;
            bottom: 30px;
            height: 55px;
        }
    }
    .content-wrapper {
        h3 {
            color: $green;
            font-size: 24px;
            margin-bottom: 25px;
        }
    }
    hr {
        opacity: 0.2;
        margin: 25px 0;
    }
    .partners-wrapper {
        border-top: 1px solid #e9e9e9;
        padding: 50px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 15px;
            border: 1px solid #e2e2e5;
            padding: 15px;
            width: 25%;
            figure {
                height: 100px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-height: 90px;
                }
            }
            button {
                @include btn-outline-green;
                font-size: 14px;
            }
        }
    }
    @include media("<=mobile-landscape") {
        .page-title-bar {
            padding: 70px 0;
            &:after {
                background: url("#{$images-path}title-divider-mobile.svg") bottom left no-repeat;
            }
            h1 {
                font-size: 36px;
            }
        }
        .partners-wrapper {
            flex-direction: column;
            margin: 30px 0;
            a {
                width: 100%;
                margin-bottom: 24px;
            }
        }
    }
}
