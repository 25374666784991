.entry-detail-slider {
    max-height: 400px;
    overflow: hidden;
    /*opacity: 0;*/
    margin-bottom: 10px;

    &-thumbnails {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 10px;

        a {
            margin-right: 10px;
            display: inline-block;
            width: 90px;
            height: 90px;
            position: relative;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                border: 5px solid transparent;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
            }

            img {
                width: 100%;
                height: auto;
            }

            &.active {
                &:before {
                    border-color: $green;
                }
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 8px;

        button {
            width: 7px;
            height: 7px;
            background-color: #fff;
        }
    }

    .slick-arrow {
        width: 50px;
        height: 50px;
        padding: 0;
        position: absolute;
        text-indent: -9999px;
        border: none;
        bottom: 30px;
        z-index: 3;
        margin-bottom: 0;

        &.slick-next {
            right: 20px;
            background: url("#{$images-path}chevron-thin-right-green.svg") #fff 50% 50% no-repeat;
            background-size: 30%;

            &:hover {
                background: url("#{$images-path}chevron-thin-right.svg") $green 50% 50% no-repeat;
            }
        }

        &.slick-prev {
            right: 90px;
            background: url("#{$images-path}chevron-thin-left-green.svg") #fff 50% 50% no-repeat;
            background-size: 30%;

            &:hover {
                background: url("#{$images-path}chevron-thin-left.svg") $green 50% 50% no-repeat;
            }
        }

        &:hover {
            &:after {
                display: none;
            }
        }
    }

    >a {
        &:not(:first-child) {
            display: none;
            content-visibility: hidden;
            /*contain-intrinsic-size: auto 340px auto 195px;*/
        }
    }

    @include media("<=mobile-landscape") {
        margin-bottom: 22px;

        &:after,
        &:before {
            display: none;
        }

        .slick-slide {
            height: 195px;
            overflow: hidden;

            img {
                height: 195px;
                max-width: inherit;
                width: auto;
                transform: translateX(-25%);
            }
        }

        img {
            height: 195px;
            overflow: hidden;
            max-width: inherit;
            width: auto;
            transform: translateX(-25%);
        }

        &-thumbnails {
            display: none;
        }

        .slick-arrow {
            display: none !important;
        }
    }

    &.loaded {
        opacity: 1;
    }
}

main.loaded {
    .entry-detail-slider {
        opacity: 1;
    }
}