.city-select-modal {
    display: none;
    width: 380px;
    padding: 38px;
    background-color: #000;
    box-shadow: 0px 30px 73px 0 rgba(0, 0, 0, 0.45);
    h2 {
        font-size: 40px;
        text-transform: none;
        color: #fff;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -1px;
        margin-bottom: 50px;
    }
    &.fancybox-content {
        min-width: unset;
        min-height: unset;
    }
    .fancybox-close-small {
        color: $green;
        opacity: 1;
        padding: 5px;
    }
    ul {
        width: 200px;
        margin: 0 auto 50px auto;
        li {
            position: relative;
            span {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: red;
            }
            a {
                display: block;
                text-align: center;
                padding: 12.5px 18px;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.45px;
                color: #ffffff;
                text-transform: uppercase;
                &:hover {
                    background-color: $green;
                }
            }
        }
    }
    @media (max-width: 568px) {
        width: 80%;
        h2 {
            font-size: 32px;
            text-align: center;
            margin-bottom: 30px;
        }
        ul {
            margin-bottom: 0;
            li {
                a {
                    font-size: 16px;
                }
            }
        }
    }
}
