.tour-box {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #e2e2e5;
    overflow: hidden;
    figure {
        position: relative;
        display: block;
        padding-bottom: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
        .tag {
            height: 29px;
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            .tag-name {
                background-color: #00aa6c;
                color: #fff;
                padding: 0 13px;
                line-height: 29px;
                display: inline-block;
                font-size: 15px;
                font-weight: 500;
                border-radius: 2px;
            }
        }
    }
    .infos-holder {
        padding: 20px 10px 0 10px;
        display: block;
        position: relative;
        h4 {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.4px;
            margin-bottom: 22px;
            color: #000;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 40px;
            min-height: 32px;
        }
        img.flag {
            width: 24px;
            height: auto;
            border-radius: 50%;
            position: absolute;
            left: 15px;
            top: -15px;
            border: 2px solid #fff;
        }
        .duration {
            @include duration;
        }
    }
    .price-holder {
        padding: 0 10px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            color: #000;
            letter-spacing: -0.5px;
            span {
                font-size: 23px;
            }
        }
        .btn {
            @include btn-outline-green;
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 667px) {
        display: block;
        height: auto;
        margin-bottom: 10px;
        figure {
            height: auto;
            width: 100%;
            img {
                width: 100%;
                height: auto;
            }
            .tag {
                height: 24px;
                .tag-name {
                    line-height: 24px;
                    font-size: 12px;
                }
            }
        }
        .infos-holder {
            padding: 10px 10px 0;
            h4 {
                padding: 0;
                font-size: 12px;
                line-height: 14px;
                @include threeline-clamp;
                margin-bottom: 8px;
                min-height: 42px;
            }
            .duration {
                margin-bottom: 12px;
                font-size: 12px;
            }
        }
        .price-holder {
            @include price-holder-mobile;
        }
    }
    &:hover {
        border-color: #d4d4d4;
    }
}
