.event-box {
    border: solid 1px #333333;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    figure {
        width: 100%;
        position: relative;
        display: block;
        padding-bottom: 100%;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
        .tag {
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: $green;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.35px;
            line-height: normal;
            padding: 11px 20px;
        }
    }
    .infos-holder {
        padding: 20px 10px 0 10px;
        height: 86px;
        h4 {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.4px;
            margin-bottom: 9px;
        }
    }
    .date-holder {
        padding: 0 10px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .date {
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            color: #bfbfc4;
            letter-spacing: -0.5px;
            span {
                font-size: 26px;
            }
        }
        .btn {
            @include btn-outline-green;
            font-size: 14px;
            border-width: 2px;
            padding: 9px 19px;
        }
    }
}
