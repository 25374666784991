.main.home {
    @include media(">=desktop") {
        padding-top: 0;
    }
}

//plans
.plans {
    background: $black;
    color: $white;
    margin-bottom: 65px;
    position: relative;
    @include media(">=desktop") {
        margin-bottom: 120px;
    }
    @include media(">=large-desktop") {
        margin-bottom: 145px;
    }
    .decor {
        position: absolute;
        left: 0;
        bottom: -30px;
        width: 57px;
        height: 60px;
    }
    .container {
        padding: 40px 15px 50px;
        z-index: 2;
        @include media(">=tablet-landscape") {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @include media(">=desktop") {
            padding: 70px 15px 60px;
        }
        @include media(">=large-desktop") {
            padding: 60px 15px 70px;
        }
    }
    h2,
    .h2 {
        color: $white;
        margin-bottom: 30px;
        @include media(">=desktop") {
            font-size: 42px;
            line-height: 50px;
            text-align: left;
        }
        @include media(">=large-desktop") {
            font-size: 50px;
            line-height: 60px;
        }
    }
    h3,
    .h3 {
        color: $white;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .icon {
        width: 34px;
        height: 43px;
        margin-bottom: 10px;
        @include media(">=desktop") {
            width: 40px;
            float: left;
            margin-right: 25px;
        }
        img {
            max-height: 100%;
        }
    }
    .description {
        overflow: hidden;
        color: $gray;
        @include media(">=desktop") {
            padding-top: 7px;
        }
    }
    .button {
        padding-left: 38px;
        padding-right: 38px;
        margin-bottom: 0;
    }
    .btn-holder {
        text-align: center;
        padding-top: 10px;
        @include media(">=tablet") {
            text-align: left;
            padding-top: 14px;
        }
    }
    .title-holder {
        @include media(">=tablet-landscape") {
            width: 30%;
            padding-right: 20px;
        }
    }
    .line {
        @include media("<desktop") {
            display: none;
        }
        position: absolute;
        left: -20px;
        top: 0;
        height: 100%;
        @include media(">=large-desktop") {
            left: -72px;
        }
    }
}

.plans-items {
    @include media(">=tablet") {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    @include media(">=tablet-landscape") {
        width: 70%;
    }
    @include media(">=desktop") {
        padding-left: 50px;
    }
    .item {
        padding-bottom: 47px;
        &:last-child {
            padding-bottom: 0;
        }
        @include media(">=tablet") {
            width: 48%;
        }
        @include media(">=desktop") {
            padding-bottom: 0;
        }
        @include media(">=large-desktop") {
            padding-right: 100px;
        }
    }
}

//franchise-section
.franchise-section {
    padding-bottom: 25px;
    @include media(">=desktop") {
        padding-bottom: 0;
    }
    .heading {
        @include media(">=desktop") {
            display: flex;
            justify-content: space-between;
            padding-bottom: 60px;
            align-items: center;
        }
        @include media(">=large-desktop") {
            padding-bottom: 75px;
        }
        .button {
            margin: 0;
        }
    }
    h2,
    .h2 {
        text-align: left;
        margin-bottom: 42px;
        @include media(">=tablet") {
            text-align: center;
        }
        @include media(">=desktop") {
            font-size: 42px;
            line-height: 50px;
            text-align: left;
            margin-bottom: 0;
        }
        @include media(">=large-desktop") {
            font-size: 50px;
            line-height: 60px;
        }
    }
    .heading {
        text-align: center;
    }
}

.franchise-list {
    @include media("<desktop") {
        display: none;
    }
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px 0 -13px;
    li {
        width: 18%;
        margin: 0 11px 5px;
        @include media(">=large-desktop") {
            width: 18.5%;
        }
    }
    .image {
        height: 230px;
        border-bottom: 4px solid $green;
        background: {
            repeat: no-repeat;
            position: 50% 50%;
            size: cover;
        }
        @include media(">=large-desktop") {
            height: 320px;
        }
        a {
            display: block;
            height: 100%;
        }
    }
    h4 {
        background: $black;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        padding: 16px 15px 16px 37px;
        position: relative;
        @include media(">=large-desktop") {
            font-size: 18px;
            padding: 20px 15px 20px 37px;
        }
        &:before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $green;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        a {
            color: $white;
            transition: color 0.3s;
            &:hover {
                @include media(">=desktop") {
                    color: $green;
                }
            }
        }
    }
}

//upcoming-events
.upcoming-events {
    position: relative;
    z-index: 20;
    @include media(">=desktop") {
        margin-bottom: 115px;
    }
    .container {
        max-width: 1140px;
        @include media(">=tablet-landscape") {
            background: $white;
        }
        @include media(">=desktop") {
            padding-top: 15px;
        }
        @include media(">=large-desktop") {
            max-width: 1520px;
            padding-bottom: 20px;
        }
    }
    .title {
        padding: 20px 25px 25px;
        background: $white;
        @include media(">=desktop") {
            float: left;
            width: 160px;
            padding: 15px;
            margin-right: 23px;
        }
        @include media(">=large-desktop") {
            width: 300px;
            padding: 30px 35px;
            margin-right: 0;
        }
    }
    h3,
    .h3 {
        margin-bottom: 0;
        @include media(">=large-desktop") {
            font-size: 20px;
        }
    }
}

.upcoming-events-slider {
    margin: 0 auto;
    padding: 0 0 60px;
    width: 100%;
    max-width: 350px;
    @include media(">=tablet") {
        max-width: none;
    }
    @include media(">=desktop") {
        padding: 0 35px 0 0;
    }
    @include media(">=large-desktop") {
        padding-right: 80px;
    }
    .slick-slide {
        > div {
            @include media(">=large-desktop") {
                margin-right: 50px;
            }
        }
    }
    .item {
        position: relative;
        overflow: hidden;
    }
    .image {
        width: 101px;
        height: 74px;
        float: left;
        margin-right: 15px;
        @include media(">=desktop") {
            margin-right: 10px;
            height: 71px;
        }
        @include media(">=large-desktop") {
            width: 130px;
            height: 90px;
            margin-right: 22px;
        }
    }
    img {
        height: 100%;
        max-height: 100%;
    }
    .info {
        overflow: hidden;
        font-weight: 700;
        @include media(">=tablet") {
            padding-right: 10px;
        }
        @include media(">=desktop") {
            padding-right: 35px;
            font-size: 14px;
            line-height: 20px;
            height: 95px;
        }
        a {
            color: $black;
        }
        p {
            @include media(">=desktop") {
                margin-bottom: 0;
            }
        }
    }
    .date {
        @include media("<desktop") {
            display: none;
        }
        text-transform: uppercase;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        font-weight: 700;
    }
    .arrow {
        position: absolute;
        right: 36px;
        bottom: -8px;
        @include media(">=desktop") {
            right: 30px;
            bottom: 0;
        }
        @include media(">=large-desktop") {
            right: 0;
        }
    }
    .slick-arrow {
        position: absolute;
        bottom: 0;
        padding: 0;
        text-indent: -9999px;
        overflow: hidden;
        width: 60px;
        height: 60px;
        border: 3px solid $green;
        right: -27px;
        top: -15px;
        background: $white url("#{$images-path}arrow-right-green.svg") 50% 50% no-repeat;
        &:after {
            display: none;
        }
        @include media(">=desktop") {
            transition: background-color 0.35s;
            bottom: auto;
            &:hover {
                background: $green url("#{$images-path}arrow-right-white.svg") 50% 50% no-repeat;
            }
        }

        &.slick-prev {
            transform: rotate(180deg);
            @include media(">=desktop") {
            }
        }

        &.slick-next {
            @include media(">=desktop") {
                top: 42px;
            }
        }
    }
}

//bike-tours
.bike-tours {
    margin-bottom: 17px;
    @include media(">=desktop") {
        background: url("#{$images-path}bike-tours-line.svg") 110px 0 repeat-x;
        margin-bottom: 35px;
    }
    @include media(">=large-desktop") {
        padding-top: 30px;
        margin-bottom: 110px;
    }
    h2,
    .h2 {
        margin-bottom: 30px;
        @include media(">=desktop") {
            text-align: left;
            font-size: 42px;
            line-height: 50px;
            max-width: 75%;
            margin-bottom: 65px;
        }
        @include media(">=large-desktop") {
            font-size: 50px;
            line-height: 60px;
        }
    }
    .btn-holder {
        text-align: center;
        @include media(">=desktop") {
            position: absolute;
            right: 6px;
            top: 0;
        }
        @include media(">=large-desktop") {
            right: 15px;
        }
    }
    .slick-dots {
        margin-top: 13px;
    }
}

.bike-tours-slider {
    padding-bottom: 33px;
    max-width: 450px;
    margin: 0 auto;
    @include media(">=tablet") {
        max-width: none;
    }
    @include media(">=desktop") {
        margin: 0 -20px;
    }
    @include media(">=large-desktop") {
        margin: 0 -15px;
    }
    .slick-slide {
        > div {
            @include media(">=tablet") {
                margin: 0 10px;
            }
            @include media(">=desktop") {
                margin: 0 22px;
            }
            @include media(">=large-desktop") {
                margin: 0 15px;
            }
        }
    }
    .image {
        height: 207px;
        position: relative;
        background: {
            repeat: no-repeat;
            position: 50% 50%;
            size: cover;
        }
        @include media(">=desktop") {
            height: 250px;
        }
        @include media(">=large-desktop") {
            height: 350px;
        }
        a {
            display: block;
            height: 100%;
        }
    }
    .info {
        background: $black;
        padding: 20px 20px 33px 20px;
        color: $white;
        position: relative;
        overflow: hidden;
        @include media(">=desktop") {
            margin: -85px 0 0 auto;
            padding-bottom: 60px;
            width: 300px;
        }
        @include media(">=large-desktop") {
            width: 375px;
            padding-left: 30px;
            padding-bottom: 65px;
            margin-top: -90px;
        }
    }
    .more {
        @include media(">=desktop") {
            position: absolute;
            bottom: 25px;
            left: 20px;
        }
        @include media(">=large-desktop") {
            left: 30px;
        }
    }
    .date {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 9px;
        @include media(">=desktop") {
            font-size: 14px;
        }
    }
    h4 {
        color: $white;
        margin-bottom: 38px;
        a {
            color: $white;
        }
    }
    .decor {
        width: 57px;
        height: 60px;
        position: absolute;
        right: -10px;
        bottom: 0;
    }
    .slick-arrow {
        position: absolute;
        bottom: 0;
        left: 50%;
        padding: 0;
        text-indent: -9999px;
        overflow: hidden;
        width: 60px;
        height: 60px;
        border: none;
        z-index: 20;
        background: $black url("#{$images-path}arrow-right-white.svg") 50% 50% no-repeat;
        &:after {
            display: none;
        }
        @include media(">=desktop") {
            transition: background-color 0.35s;
            bottom: auto;
            top: 40%;
            margin-top: -55px;
            &:hover {
                background-color: $green;
            }
        }
        @include media(">=large-desktop") {
            margin-top: -45px;
        }

        &.slick-prev {
            transform: rotate(180deg);
            margin-left: -65px;
            @include media(">=desktop") {
                margin-left: 0;
                left: -20px;
            }
            @include media(">=large-desktop") {
                left: -60px;
            }
        }

        &.slick-next {
            margin-left: 5px;
            @include media(">=desktop") {
                margin-left: 0;
                left: auto;
                right: -20px;
            }
            @include media(">=large-desktop") {
                right: -60px;
            }
        }
    }
}

.slick-dots {
    li {
        display: inline-block;
        vertical-align: top;
        margin: 0 6px;
        &.slick-active {
            button {
                background: $black;
            }
        }
    }
    button {
        background: none;
        border: none;
        min-width: 1px;
        width: 7px;
        height: 7px;
        background: #fff;
        border-radius: 50%;
        padding: 0;
        margin: 0;
        text-indent: -9999px;
        box-shadow: none;
        transition: background-color 0.3s;
        &:after {
            display: none;
        }
    }
}

.fareharbor-calendar-wrap iframe {
    min-height: 348px;
}