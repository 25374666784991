.review-section {
    background-color: #FAFAFA;

    .container {
        max-width: 1215px;
    }
}

.review-slider-wrapper {
    position: relative;

    /*margin-bottom: 12px;*/
    padding: 60px 0;

    .review-slider {
        position: relative;
        max-height: 400px;
        overflow: hidden;

        .slide {
            height: 340px;
            position: relative;
            background-size: cover;
            background-position: center center;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
    }

    .section-title {
        margin-bottom: 0;
        text-align: center !important;
    }

    .review-slider {
        .slide-box {
            display: flex;
            gap: 50px;

            /*padding: 0 6vw;*/
            width: 100%;
            max-width: 950px;
            align-items: center;
        }

        .slide-text {
            max-width: 100%;

            h3 {
                font-size: 20px;
                line-height: 22px;
                font-weight: 500;
                margin-bottom: 20px !important;
            }
        }
    }

    .section-title {
        margin-bottom: 30px;
    }

    .review-slider {
        .slide-img {
            min-width: 250px;
            width: 100%;
        }

        .slide {
            .text-holder {
                height: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                padding-left: 130px;
                max-width: 50%;
                color: #fff;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;

                h3 {
                    text-transform: uppercase;
                    font-size: 36px;
                    line-height: normal;
                    font-weight: bold;
                    letter-spacing: -0.9px;
                    margin-bottom: 30px;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: -0.45px;
                    margin-bottom: 22px;
                }

                button {
                    border: 3px solid #fff;
                    padding: 9px 16px;
                    color: #fff;
                    font-weight: bold;
                    line-height: normal;
                    margin: 0;
                    height: auto;
                    background-color: transparent;
                    text-transform: none;
                    font-size: 18px;

                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }

            img {
                &.mobile {
                    display: none;
                }

                &.desktop {
                    display: block;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }

            &.overlay-active:before {
                content: " ";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 35px;
                right: 20%;
                background: #26c438;
                background: -webkit-gradient(linear, left top, right top, from(#26c438), to(rgba(38, 196, 56, 0)));
                background: linear-gradient(90deg, #26c438 0%, rgba(38, 196, 56, 0) 100%);
                z-index: 1;
            }
        }
    }

    .arrow {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #000;
        z-index: 9;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        &.prev {
            left: 20px;
            background: url("../images/chevron-thin-left.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        &.next {
            right: 20px;
            background: url("../images/chevron-thin-right.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        &:hover {
            background-color: #26c438;
        }
    }

    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;

        li {
            margin: 0 15px;
            height: 11px;
            margin: 0 10px;

            button {
                width: 12px;
                height: 12px;
                border: 1px solid #bfbfc4;
            }

            &.slick-active button {
                background-color: #000 !important;
                border: 1px solid #000;
            }
        }

        display: block !important;
        bottom: 0;
    }
}

@media (max-width: 1140px) {
    .review-slider-wrapper .review-slider .slide-box {
        max-width: 75vw;
    }
}

@media (max-width: 768px) {
    .review-slider-wrapper .section-title {
        margin: 0 !important;
    }

    .group-detail-one-page .call-to-action {
        width: 100% !important;
        padding: 40px 20px 60px !important;
        margin-bottom: 0 !important;
    }

    .client-section {
        flex-direction: column;
        padding: 0 !important;
    }

    .group-detail-page {
        section.call-to-action>.grid {
            padding: 0 0 70px !important;
        }

        .faq {
            padding-top: 30px !important;
        }
    }

    .review-slider-wrapper {
        margin: 0 -10px;

        .arrow {
            display: none;
        }

        .review-slider .slide {
            height: 375px;

            .text-holder {
                max-width: 70%;
                padding-left: 10px;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                padding-bottom: 25px;

                h3 {
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 10px;
                }

                p {
                    display: none;
                }

                button {
                    border-width: 3px;
                    padding: 8px;
                    font-size: 14px;
                }
            }

            img {
                &.mobile {
                    display: block;
                    width: 100%;
                    height: 365px;
                    -o-object-fit: contain;
                    object-fit: contain;
                }

                &.desktop {
                    display: none;
                }
            }

            &.overlay-active:before {
                left: 0;
                top: 30%;
                bottom: 0;
                right: 0;
                background: #26c438;
                background: -webkit-gradient(linear, left top, left bottom, from(rgba(38, 196, 56, 0)), to(#26c438));
                background: linear-gradient(180deg, rgba(38, 196, 56, 0) 0%, #26c438 100%);
                z-index: 1;
            }
        }
    }
}

.price p {
    margin: 0 !important;
}

.bike-box-rental-sales {
    display: flex !important;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;

    p {
        font-size: 17px;
        text-decoration: line-through;
    }

    b {
        font-size: 25px;
        font-weight: 600;
    }
}


/*------*/

.price-sales {
    span {
      color: gray;
      font-size: 12px !important;
      text-decoration: line-through;
    }
  
    p {
      color: black;
      display: inline-block;
      vertical-align: text-top;
      margin: 0;
    }
  }
  
  .price-sales-rental {
    display: flex;
    flex-direction: column;
    line-height: 100%;
  
    p {
      &:first-child {
        font-size: 16px !important;
        text-decoration: line-through;
        margin: 0;
        color: gray;
  
        span {
          font-size: 16px !important;
        }
      }
  
      &:nth-child(2) {
        span {
          font-size: 16px !important;
          font-weight: bold;
        }
  
        font-size: 16px !important;
        color: black;
        display: inline-block;
        margin: 0;
      }
    }
  }
  
  .rental-detail-page .bottom-rental-bar .holder .prices .rental-bar-sale {
    display: flex;
    gap: 7px;
  
    span {
      font-size: 30px;
    }
  
    b {
      font-size: 20px;
      text-decoration: line-through;
      height: 100%;
      color: #a9a8a8;
    }
  }
  
  .rental-box .price-holder .price.price-sales {
    font-size: 14px;
  }
  
  .tour-box .price-holder .price.price-sales {
    font-size: 18px;
  
    p {
      font-size: 21px;
    }
  }
  
  .tour-detail-page .sidebar .starting .starting-price-sales {
    > div {
      text-align: center;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  
    b {
      font-weight: bold;
      font-size: 20px;
      color: gray;
      text-decoration: line-through;
    }
  }
  
  .rental-detail-page .sidebar .starting .starting-price-sales b {
    font-weight: bold;
    font-size: 20px;
    color: gray;
    text-decoration: line-through;
  }
  
  .tour-detail-page .sidebar .starting .starting-price-sales span, .rental-detail-page .sidebar .starting .starting-price-sales span {
    font-size: 30px;
  }
  
  .tour-detail-page .book-now-button-mobile .price-box-sales-mobile .from {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  @media (max-width: 900px) {
    .price-sales-rental {
      flex-direction: row;
      gap: 6px;
    }
  
    .tour-box .price-holder {
      flex-direction: column;
    }
  
    section.bike-prices .bikes-slider-wrapper .bikes-slider .bike-box figure img {
      object-fit: contain;
    }
  
    .tour-detail-page .sidebar .starting .starting-price-sales b, .rental-detail-page .sidebar .starting .starting-price-sales b {
      font-size: 17px !important;
    }
  
    .tour-detail-page .sidebar .starting .starting-price-sales span, .rental-detail-page .sidebar .starting .starting-price-sales span {
      font-size: 22px !important;
    }
  
    .tour-detail-page .sidebar .starting {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  
  @media (max-width: 768px) {
    .price-sales-rental {
      flex-direction: row;
      font-size: 13px !important;
      gap: 6px;
    }
  }
  
  @media (max-width: 667px) {
    .tour-detail-page .book-now-button-mobile .price-box-sales-mobile {
      b {
        font-size: 20px !important;
        text-decoration: line-through;
        color: #d1d1d1;
      }
  
      .price-box-new-price {
        font-size: 30px !important;
        font-weight: bold;
      }
    }
  
    .price-sales-rental {
      flex-direction: row;
      gap: 6px;
    }
  
    .tour-detail-page .book-now-button-mobile .price-box .btn {
      padding: 12px 18px !important;
    }
  }