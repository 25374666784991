.bike-detail-page {
    .breadcrumbs {
        border-bottom: none;
        position: relative;
        z-index: 99;
        @include media("<=mobile-landscape") {
            border-bottom: 1px solid #e9e9e9;
            margin: 0 -9px;
        }
    }

    .hero {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            top: 0;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 50%,
                rgba(255, 255, 255, 0.87) 78.32%,
                #ffffff 95%
            );
            z-index: 2;
        }
        h1 {
            position: relative;
            z-index: 3;
            font-weight: normal;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.35em;
            @include media("<=mobile-landscape") {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.35em;
            }
        }
    }

    .bike-image {
        max-height: 400px;
        overflow: hidden;
        @include media("<=mobile-landscape") {
            max-height: 140px;
            padding-top: 15px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }

    .key-features-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        @include media("<=mobile-landscape") {
            flex-direction: column;
            margin-bottom: 10px;
        }
        .key-feature-item {
            width: 33%;
            border-left: 2px solid $green;
            padding-left: 30px;
            margin: 0 50px;
            font-size: 14px;
            @include media("<=mobile-landscape") {
                width: 100%;
                padding-left: 10px;
                margin: 0 0 10px 0;
            }
            h4 {
                font-size: 14px;
                margin-bottom: 5px;
                line-height: 1;
                @include media("<=mobile-landscape") {
                    font-size: 12px;
                }
            }
            p {
                margin-bottom: 0;
                line-height: 22px;
                @include media("<=mobile-landscape") {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }

    .features-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        @include media("<=mobile-landscape") {
            flex-direction: column;
            margin-top: 40px;
        }
        .feature-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33%;
            flex-direction: column;
            margin: 0 0 50px;
            font-size: 14px;
            padding: 0 45px;
            @include media("<=mobile-landscape") {
                width: 100%;
                margin: 0 0 24px 0;
                padding: 0 15px;
            }
            h4 {
                font-size: 14px;
                margin-bottom: 5px;
                line-height: 1;
                @include media("<=mobile-landscape") {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            p {
                margin-bottom: 0;
                line-height: 22px;
                text-align: center;
                @include media("<=mobile-landscape") {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            figure {
                margin-bottom: 30px;
                @include media("<=mobile-landscape") {
                    margin-bottom: 15px;
                }
                img {
                    border-radius: 50%;
                }
            }
        }
    }

    .specs {
        @include media("<=mobile-landscape") {
            margin-bottom: 30px;
            &.active {
                .grid {
                    display: block;
                }
                .specs-mobile-title:before {
                    display: none;
                }
            }
        }
        .grid {
            margin: 0 -50px;
            @include media("<=mobile-landscape") {
                border: 1px solid #e9e9e9;
                display: none;
                margin: 0;
            }
            .col-12 {
                padding: 0 50px;
                @include media("<=mobile-landscape") {
                    padding: 20px 10px;
                }
            }
        }
        h2 {
            font-size: 36px;
            margin-bottom: 46px;
            @include media("<=mobile-landscape") {
                background-color: $green;
                font-size: 18px;
                line-height: 22px;
                color: #fff;
                padding: 22px 14px;
                margin-bottom: 0;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 16px;
                    background-color: #fff;
                    right: 26px;
                    top: 50%;
                    margin-top: -7.5px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 16px;
                    height: 3px;
                    background-color: #fff;
                    right: 20px;
                    top: 50%;
                    margin-top: -1.5px;
                }
            }
        }
        .spec-item {
            h3 {
                color: $green;
                font-size: 16px;
                margin-bottom: 8px;
                font-weight: 500;
                text-transform: uppercase;
            }
            table {
                width: 100%;
                border: none;
                @include media("<=mobile-landscape") {
                    font-size: 12px;
                    margin-bottom: 0;
                }
                tr {
                    td {
                        border: none;
                        padding: 10px 0;
                        vertical-align: top;
                        &:first-child {
                            font-weight: 500;
                            width: 25%;
                        }
                    }
                    &:not(:last-child) {
                        td {
                            border-bottom: 1px solid #e9e9e9;
                        }
                    }
                }
            }
        }
    }

    .similar-rentals {
        h2 {
            @include media("<=mobile-landscape") {
                font-size: 18px;
                margin-bottom: 12px;
            }
        }
    }
}
