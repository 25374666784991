.main-intro {
    background: $black;
    padding: 30px 0 0;
    position: relative;
    margin-bottom: -35px;
    overflow: hidden;
    @include media(">=tablet-landscape") {
        height: calc(100vh - 60px);
        padding: 0;
    }
    @include media(">=desktop") {
        height: 100vh;
        margin-bottom: -117px;
    }
    .logo {
        position: absolute;
        right: -61px;
        top: 0;
        width: 140px;
        height: 307px;
        @include media(">=tablet-landscape") {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }
        @include media(">=desktop") {
            width: auto;
            height: 100vh;
        }
    }
    .container {
        @include media(">=tablet-landscape") {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: calc(100vh - 60px);
        }
        @include media(">=desktop") {
            height: 100vh;
        }
    }
    .decor {
        @include media("<desktop") {
            display: none;
        }
        position: absolute;
        left: -6px;
        width: 54px;
        height: 60px;
        top: 287px;
        @include media(">=desktop") {
            top: 360px;
        }
    }
    h1,
    .h1 {
        color: $white;
        @include media(">=desktop") {
            font-size: 50px;
            line-height: 60px;
            text-align: left;
            margin-bottom: 5px;
        }
        @include media(">=large-desktop") {
            font-size: 68px;
            line-height: 76px;
            margin-bottom: 20px;
        }
    }
    .title {
        svg {
            width: 250px;
            display: block;
            margin: -80px 0 -16px;
            @include media(">=tablet") {
                margin: -60px auto -15px;
            }
            @include media(">=tablet-landscape") {
                margin: -65px 0 -16px;
            }
            @include media(">=desktop") {
                width: 500px;
                margin: 0 0 20px;
            }
            @include media(">=large-desktop") {
                width: 630px;
                margin-bottom: 50px;
            }
        }
    }
    .info {
        padding-bottom: 30px;
        @include media(">=tablet") {
            text-align: center;
        }
        @include media(">=tablet-landscape") {
            text-align: left;
            width: 50%;
            margin-top: -60px;
        }
        @include media(">=large-desktop") {
            margin-top: 25px;
        }
        .button {
            width: 185px;
            text-align: center;
            margin-bottom: 0;
        }
    }
    .main-image {
        position: relative;
        margin: 0 -15px;
        height: 45vh;
        min-height: 250px;
        overflow: hidden;
        background: $black;
        @include media(">=tablet-landscape") {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height: 100vh;
        }
        .mask {
            background: {
                repeat: no-repeat;
                position: 50% 50%;
                size: cover;
            }
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            bottom: 0;
            opacity: 0.8;
        }
    }
    video {
        display: none;
        .no-touch & {
            display: block;
            min-width: 100%;
            min-height: 100%;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            z-index: 3;
        }
    }
}
