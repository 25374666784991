.swal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.swal-modal {
    background-color: #fff;
    border-top: 10px solid $green;
}

.swal-title {
    font-size: 34px;
    line-height: 42px;
    color: $black;
}

.swal-text {
}

.swal-footer {
    background-color: rgb(245, 248, 250);
    margin-top: 32px;
    border-top: 1px solid #e9eef1;
    overflow: hidden;
}

.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #4962b3;
    font-size: 12px;
    border: 1px solid #3e549a;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
