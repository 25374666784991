.tags-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    li {
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        border-radius: 58px;
        padding: 7px 11px;
        margin-right: 10px;
        &:nth-of-type(1) {
            background: #b729a8;
        }
        &:nth-of-type(2) {
            background: #5342d6;
        }
        &:nth-of-type(3) {
            background: #fec053;
        }
    }
    @include media("<=mobile-landscape") {
        li {
            font-size: 10px;
            padding: 5px 9px;
        }
    }
}
