.breadcrumbs {
    padding: 12px 15px;
    margin: 0 auto 20px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: $black;
    position: relative;
    border-bottom: 1px solid #e9e9e9;
    @include media("<=tablet-landscape") {
        max-width: 980px;
        margin: 0 auto 20px auto;
    }
    @include media(">=desktop") {
        max-width: 1140px;
        padding: 15px 0 20px;
        margin-bottom: 20px;
    }

    .arrow {
        background: url("#{$images-path}seperator-arrow.svg") center center no-repeat;
        background-size: contain;
        width: 6px;
        height: 11px;
        margin: 0 14px;
        display: inline-block;
    }

    li {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 25px 5px 0;

        &:not(:last-child) {
            &:after {
                position: absolute;
                left: 100%;
                top: 4px;
                content: "";
                width: 2px;
                height: 11px;
                margin-left: 11px;
                background-color: $black;
            }
        }

        &:last-child {
            a {
                color: $green;
            }
        }
    }

    a {
        color: $black;
        transition: color 0.3s;
        font-weight: normal;

        &:hover {
            @include media(">=desktop") {
                color: $green;
            }
        }
    }

    .breadcrumb_last {
        color: $green;
    }

    @include media("<=mobile-landscape") {
        white-space: nowrap;
        overflow-x: auto;
        margin-bottom: 10px;
        padding: 9px;
        font-size: 12px;
        a {
            font-size: 12px;
        }
        .arrow {
            height: 9px;
            margin: 0 9px;
        }
    }
}
