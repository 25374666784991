.bike-carousel {
    .slick-slider {
        max-height: 0px;
        overflow: hidden;
        display: none;
        &.slick-initialized {
            display: block;
            max-height: unset;
        }
    }
    .slick-track {
        @include media("<tablet") {
        }
    }
    .carousel {
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        @include media("<tablet") {
            margin-left: -5px;
            margin-right: -5px;
        }
        .slide {
            height: 100%;
            @include media(">=tablet") {
                width: 25%;
            }
            @include media("<tablet") {
                width: 50%;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .item {
            position: relative;
            text-align: center;
            border: 1px solid #e9e9e9;
            padding: 20px 20px 90px;
            height: 100%;
            background-color: #fff;
            h5 {
                line-height: 24px;
                margin-bottom: 5px;
                margin-top: 20px;
                height: 48px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            figure {
                position: relative;
                width: 100%;
                padding-bottom: 56%;
                display: block;
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: top center;
                }
            }
            .price {
                display: block;
                font: 700 20px/28px $main-ff;
                @include media(">=desktop") {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
            .button {
                @include btn-outline-green;
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
                padding: 15px 0;
                text-transform: uppercase;
                max-width: 85%;
            }
        }
    }
    .arrow {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #000;
        z-index: 9;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.5;
        &.prev {
            left: -8px;
            background: url("#{$images-path}chevron-thin-left.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            transform: translateY(-50%);
        }
        &.next {
            right: -8px;
            background: url("#{$images-path}chevron-thin-right.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            transform: translateY(-50%);
        }
        &:hover {
            opacity: 1;
        }
        @include media("<=tablet") {
            width: 30px;
            height: 30px;
            &.prev {
                left: 0;
            }
            &.next {
                right: 0;
            }
        }
    }
}
