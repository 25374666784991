.terms-holder {
    padding-top: 100px;
    @include media(">=large-desktop") {
        padding-top: 166px;
    }
}

article {
    max-width: 1035px;
    margin: 0 auto;
    padding: 0 15px;
    h3,
    .h3 {
        text-transform: uppercase;
        text-align: left;
        @include media(">=desktop") {
            padding-top: 25px;
            margin-bottom: 32px;
        }
    }
    em {
        display: block;
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        color: $slate-gray;
    }
}
