@media (min-width: 768px) {
    .read-more {
        display: none;
    }
}

.group-options {
    margin-top: 60px;
}

@media (max-width: 768px) {
    .group-options {
        >h2 {
            padding-top: 30px;
        }

        margin-top: 30px;
    }

    .group-book-bottom {
        display: none !important;
    }

    .group-detail-page {
        .group-book-item-bottom {
            flex: unset !important;
            gap: 15px;
            flex-direction: column;
        }

        .group-book-item-button {
            width: 100%;
            text-align: center;
        }

        .group-book-item {
            width: calc((100% / 2) - 30px) !important;
        }

        .info-boxes-wrapper.other .info-box {
            margin: 0;
        }
    }

    .review-slider-wrapper .review-slider {
        .slide-box {
            max-width: unset !important;
            gap: 10px !important;
            flex-direction: column;
        }

        .slide-img {
            width: 64px !important;
            height: 64px !important;
            min-width: unset !important;
        }

        .slide-text h3 {
            text-align: center;
        }

        .slide-img img {
            border-radius: 50%;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        .slide-text {
            h3 {
                margin-bottom: 16px;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 10px !important;
            }
        }
    }

    .slide-text-six {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .review-slider-wrapper {
        .review-slider {
            .slide-text .read-more {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                text-decoration-line: underline;
                color: #26C438;
                font-style: italic;
            }

            .slide-box {
                padding: 20px;
            }

            .slide {
                height: max-content !important;
                min-height: 300px !important;
            }

            max-height: unset !important;
        }

        padding: 30px 0 !important;
    }
}

.group-detail-page {
    .group {
        display: flex;
        justify-content: space-between;
    }

    .group-wrapper {
        margin: 60px 0;
    }

    .container .section-title {
        text-transform: unset;
        text-align: start;
        font-size: 30px;
        line-height: 40px;
    }

    .group {
        &:not(:last-of-type) {
            margin-bottom: 30px;
        }

        &.group-reverse .group-content-wrapper {
            flex-direction: row-reverse;
        }
    }

    .group-content {
        width: 100%;
        max-width: 694px;
        padding-top: 54px;
        border-top: 2px solid #E0E0E0;
    }

    .group-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
        color: #0A0A0A;
        margin: 0 0 16px;
    }

    .group-text {
        margin: 0;
        font-size: 20px;
        line-height: 26px;
    }

    .group-images {
        width: 354px;
        background-color: #FAFAFA;
        padding: 40px 62px;
    }

    .group-image-container {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: 22px;
        }
    }

    .group-image {
        width: 42px;
        height: 42px;
        margin-right: 34px;
    }

    .group-image-text {
        font-size: 12px;
        line-height: 16px;
        color: #0A0A0A;
    }

    .group-book-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px 30px;
    }

    .group-book-item {
        margin: 20px 15px 0;
        width: calc((100% / 4) - 30px);
        min-height: 440px;
        display: flex;
        flex-direction: column;

        picture {
            width: 100%;
            height: 100%;
            max-height: 270px;
            object-fit: cover;
            display: block;

            img {
                height: 100%;
            }
        }
    }

    .group-book-item-content {
        padding: 15px;
        border: 1px solid #E9E9E9;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .group-book-item-title {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #0A0A0A;
        flex: 1;
    }

    .group-book-item-dur {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .group-book-item-dur-text {
        color: #BFBFC4;
        font-size: 16px;
        line-height: 22px;
        margin-left: 10px;
        font-weight: 500;
    }

    .group-book-item-bottom {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .group-book-item-price {
        color: #0A0A0A;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
    }

    .group-book-item-price-num {
        font-size: 24px;
    }

    .group-book-item-button {
        color: #26C438;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding: 7px 10px;
        border: 2px solid #26C438;
    }
}

.group-book-bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.group-book-bottom-link {
    display: flex;
    align-items: center;

    svg {
        transform: rotate(270deg);
    }
}

.group-book-bottom-link-text {
    color: #26C438;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-right: 10px;
}

.group-slider {
    display: flex;
    gap: 9%;
    margin-bottom: 50px;
}

.select2-container--default .select2-results__option--selected {
    background-color: transparent !important;
}

.main-slider-group {
    max-width: 53%;
    width: 100%;
}

@media (max-width: 1240px) {
    .group-detail-page .group-slider-info-title {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (max-width: 768px) {
    .main-slider-group {
        max-width: 100%;
    }

    .group-slider-info {
        max-width: unset !important;
    }

    .group-slider {
        flex-direction: column-reverse;
    }

    .group-detail-page {
        .group-slider-info-title {
            font-size: 30px;
            line-height: 40px;
        }

        .main-slider-wrapper {
            margin: 0;
        }
    }

    /*.main-slider-wrapper .main-slider{*/
    /*    max-height: 255px;*/
    /*}*/

    .main-slider-wrapper .slick-dots {
        bottom: -21px !important;
    }

    .slick-dots {
        margin: 36px 0 0 !important;
    }

    .select-your__wrapper {
        flex-direction: column;
        align-items: center !important;
        gap: 30px !important;
        padding: 40px 20px !important;
    }

    .select-wrapper {
        max-width: unset !important;
    }

    .select-your__wrapper h2 {
        margin-bottom: 0 !important;
        font-size: 18px !important;
        line-height: 28px !important;
    }

    .b-faq {
        margin: 5px auto;
    }

    .faq__item {
        display: flex;
        flex-direction: column;
    }

    .b-faq {
        .faq__title {
            position: relative;
            display: block;
            padding: 30px 20px;
            color: #0A0A0A;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
            background-color: #FFFFFF !important;
            -webkit-transition: background-color 0.2s;
            transition: background-color 0.2s;
            border-top: 1px solid #66666657;
            border-bottom: 1px solid #66666657;

            &:hover {
                background-color: #e5e4e4;
                transition: all 0.5s ease-out;
            }
        }

        .faq__active {
            background-color: #e5e4e4;
            border-bottom: unset;
            padding-bottom: 0;

            .faq__spoiler {
                top: 40px !important;
                border: unset !important;
            }
        }

        .faq__title .faq__spoiler {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            width: 48px;
            height: 48px;
            text-align: center;
            color: black;
            line-height: 30px;
            font-size: 24px;
            font-weight: 700;
            margin-right: 5px;
            border: 1px solid #E0E0E0;
            border-radius: 50%;
            -webkit-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }
    }

    .group-detail-page .group.group-reverse .group-content-wrapper {
        flex-direction: column;
    }

    .faq__active {
        border-bottom: none !important;
    }

    .b-faq {
        .faq__rotate {
            transform: rotate(225deg);
        }

        .faq__content {
            padding: 10px 20px 60px;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            display: none;
        }
    }

    .group-detail-page {
        .group-text {
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }

        .group-content {
            padding: 0;
            border: none;
        }

        .group-images {
            background-color: unset;
            padding: 20px 0;
        }
    }

    .b-faq .faq__symbol {
        font-weight: 500;
    }

    .group-content .group-title {
        display: none;
    }

    .exp-section {
        padding: 0;

        .section-title {
            text-align: center !important;
        }
    }

    .group-detail-page .group:not(:last-of-type) {
        margin-bottom: 0;
    }

    .faq__item {
        &:first-child .faq__title {
            border-top: 2px solid #66666657;
        }

        &:last-child .faq__title {
            border-bottom: 2px solid #66666657;
        }
    }

    .select-your {
        margin-bottom: 30px !important;
    }

    .group-detail-page {
        .group-wrapper {
            margin: 30px 0;
        }

        .info-boxes-wrapper.other {
            border-top: none;
            padding: 30px 0 60px;

            .section-title {
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }
}

.our-partners {
    background: #FAFAFA;
    padding: 60px 0 45px;

    >.container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
    }
}

.our-partners__wrapper {
    display: flex;
    width: 100%;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

.one-brand {
    width: 22%;
    max-width: 262px;
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.one-brand__wrapper {
    height: 187px;
    border: 1px solid #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.one-brand__img img {
    max-height: 74px;
}

.one-brand__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    font-style: normal;
    text-align: center;
}

.groups__how-it-work {
    padding: 60px 0;

    >.container {
        display: flex;
        flex-direction: column;
        gap: 35px;
        justify-content: center;
        align-items: center;
    }
}

.how-it-work__tabs {
    display: flex;
    gap: 32px;
}

.tab-services {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.tab-hide {
    display: none !important;
}

.tab-types,
.tab-benefits {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.tab-types__one {
    width: 23%;
    display: flex;
    padding: 60px 50px 30px 50px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    border: 1px solid #E9E9E9;

    &:hover {
        box-shadow: 0px 2.2538793087px 5.8851294518px 0px rgba(0, 0, 0, 0.04), 0px 18px 47px 0px rgba(0, 0, 0, 0.08);
    }

    h3 {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
    }
}

.tab-services__one {
    padding: 32px 40px;
    display: flex;
    gap: 31px;
    width: 48.5%;
    border: 1px solid #E9E9E9;
    background: #FFF;
    box-shadow: 0px 2.7930428982px 3.5910551548px 0px rgba(0, 0, 0, 0.01), 0px 9.3812494278px 12.0616064072px 0px rgba(0, 0, 0, 0.02), 0px 42px 54px 0px rgba(0, 0, 0, 0.03);
}

.tab-services-info {
    display: flex;
    flex-direction: column;
    gap: 2px;

    h3,
    p {
        margin: 0;
    }
}

.tab-services-img {
    width: max-content;

    img {
        max-width: 80px;
    }
}

.btn-tabs {
    background-color: #F2F2F2;
    border: none;
    color: #0A0A0A;
    font-weight: bold;
    padding: 13.5px 45px;

    span {
        font-size: 16px;
    }

    &.active {
        background-color: #26c438;
        color: #fff;
    }
}

@media (min-width: 768px) {

    .partners-slider-wrapper,
    .tab-services_mobile,
    .tab-benefits_mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    .how-it-work__wrapper {
        width: 100%;
    }

    .tab-services,
    .tab-benefits {
        display: none;
    }

    .tab-types {
        justify-content: center;
    }

    .tab-services__one {
        flex-direction: column;
        gap: 24px;
        align-items: center;
        text-align: center;
    }

    .tab-services__mobile {
        width: 100%;
    }

    .tab-types__one {
        width: 43%;
        padding: 0;
        gap: 30px;
        border: none;
    }

    .tab-types_mobile {
        padding: 30px 0;
    }

    .tab-types-img {
        width: 100%;
        max-width: 157px;
    }

    .tab-services-slider-wrapper,
    .tab-benefits-slider-wrapper {
        width: 100%;
        max-width: 100vw;
    }

    .tab-services__one {
        margin: 0 auto;
        max-width: 335px;
        width: 100%;
    }

    .our-partners {
        padding: 30px 0;
    }

    .our-partners__wrapper {
        display: none;
    }

    .one-brand {
        width: 270px;
        margin: 0 auto;
        max-width: unset;
    }

    .partners-slider-wrapper {
        width: 100%;

        .slick-dots {
            li {
                &.slick-active button {
                    background-color: #000 !important;
                    border: 1px solid #000;
                }

                button {
                    width: 12px;
                    height: 12px;
                    border: 1px solid #bfbfc4;
                }
            }

            margin: 0 !important;
        }
    }

    .our-partners>.container {
        gap: 0;
    }

    .group-detail-page .container .section-title {
        font-size: 24px;
        line-height: 30px;
    }

    .tab-services-slider-wrapper .slick-dots li,
    .tab-benefits-slider-wrapper .slick-dots li {
        &.slick-active button {
            background-color: #000 !important;
            border: 1px solid #000;
        }

        button {
            width: 12px;
            height: 12px;
            border: 1px solid #bfbfc4;
        }
    }

    .how-it-work__tabs {
        gap: 12px;
        flex-direction: column;
    }

    .groups__how-it-work {
        >.container {
            gap: 30px;
        }

        .section-title {
            margin-bottom: 0;
        }

        padding: 30px 0;
    }
}

.faq__title {
    display: none;
}

.group-content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.group-slider-info {
    padding-top: 20px;
    max-width: 38%;
}

.select-your {
    background: #0A0A0A;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

.select-your__wrapper {
    max-width: 1140px;
    display: flex;
    align-items: flex-end;
    gap: 55px;
    padding: 73px 20px 95px;
    width: 100%;

    h2 {
        color: #FFFFFF;
        white-space: nowrap;
        margin-bottom: 10px;
        font-size: 30px;
        line-height: 40px;
    }
}

.select-wrapper {
    max-width: 270px;
    width: 100%;
    position: relative;

    svg {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 15px;
        top: 42px;
        cursor: pointer;
        pointer-events: none;
    }
}

.choose-city-type button {
    margin-bottom: 6px;
}

.select-wrapper {
    svg.rotate {
        transform: rotate(180deg);
    }

    label {
        color: #FFFFFF;

        p {
            margin: 0;
        }
    }
}

.select2-container .select2-selection--single {
    height: 60px;
    display: flex;
    align-items: center;
    border: 1px solid #E9E9E9;
    border-radius: unset;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none;
}

.select2-container {
    width: 100% !important;
}

.select2-search--dropdown {
    display: none;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background: none !important;
    color: #26C438;
}

.select2-results {
    padding-right: 2px;
}

.select2-results__option {
    padding: 6px 15px;
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 144px !important;
    padding: 6px 0;
}

.select2-results__options {
    &::-webkit-scrollbar {
        width: 2px;

        /*margin-right: 10px;*/
    }

    &::-webkit-scrollbar-track {
        background-color: #F4F4F4;
    }

    &::-webkit-scrollbar-thumb {
        background: #26C438;
        width: 2px;
    }
}

.select2-dropdown {
    border: 1px solid #e9e9e9 !important;
}

.select2-container--default .select2-results>.select2-results__options {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.group-detail-one-page .lineHr,
.group-detail-all .lineHr {
    width: 100%;
    height: 1px;
    background: #E9E9E9;
    margin: 42px 0 30px;
}

.info-client {
    margin-right: 30px;
    width: 44vw;
    max-width: 670px;
}

.info-client__item>p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #0A0A0A;
}

.recomended-activity {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 12px;
}

.recomended-activity__item {
    display: flex;
    gap: 30px;
}

.recomended-activity>h3 {
    margin: 0;
}

.recomended-activity__item {
    >p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #0A0A0A;
    }

    img {
        max-width: 200px;
    }
}

.client-section {
    display: flex;
    margin-top: 60px;
}

.review-slider-wrapper .slick-slide img {
    object-fit: cover;
    height: 300px;
}

.group-detail-one-page {
    .group-slider {
        gap: 6% !important;
    }

    .group-slider-info {
        .lineHr {
            margin: 45px 0 20px;
        }

        >h5 {
            font-weight: 700;
            font-size: 12px;
            line-height: 100%;
        }
    }
}

.button-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .info-client-button {
        height: 28px;
        text-align: center;
        width: 164px;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        color: #FFFFFF;
        background: #26C438;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.group-four {
    background: #FAFAFA;
    padding: 60px 0px;

    >.container {
        display: flex;
        gap: 24px;
    }
}

.group-four-img {
    margin-bottom: 24px;
    width: 100%;

    img {
        width: 100%;
        max-height: 96px;
        max-width: 164px;
        object-fit: contain;
    }
}

.event-form {
    max-width: 1280px;
    margin: 60px auto 60px;

    .call-to-action {
        background: #FAFAFA;
        padding: 50px 0 !important;
    }
}

.group-detail-all.event-partners-all .grid:nth-child(3) .col-12 {
    width: 50%;
}

.group-four__box {
    h2 {
        margin: 0;
        font-size: 32px;
    }

    p {
        margin: 0;
        font-size: 18px;
    }
}

.group-four>.container:after {
    display: none;
}

.group-four__box {
    width: calc((100% - 72px)/4);
    display: flex;
    text-align: center;
    padding: 32px 8px;
    gap: 8px;
    flex-direction: column;
    border: 1px solid #E9E9E9;
    background: #FFF;
    box-shadow: 0px 2.7930428982px 3.5910551548px 0px rgba(0, 0, 0, 0.01), 0px 9.3812494278px 12.0616064072px 0px rgba(0, 0, 0, 0.02), 0px 42px 54px 0px rgba(0, 0, 0, 0.03);
}

@media (max-width: 885px) {
    .button-list .info-client-button {
        width: 145px;
    }
}

.client-section>.section-title {
    display: none;
}

.group-detail-one-page .select-your {
    margin-bottom: 0 !important;
}

.btn-started {
    display: flex;
    width: 210px;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    background-color: #26c438;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: white;
}

@media (max-width: 768px) {
    .group-four>.container {
        flex-direction: column;
        align-items: center;
    }

    .group-four__box {
        width: 100%;
        max-width: 280px;
    }

    .client-section {
        >.section-title {
            display: flex;
            text-align: center !important;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
        }

        .lineHr {
            display: none;
        }
    }

    .group-detail-one-page {
        .info-client {
            margin-right: 0;
            width: 100%;
            max-width: unset;
        }

        .main-text {
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }
    }

    .recomended-activity__item {
        img {
            max-width: 100vw !important;
            width: 100%;
        }

        flex-direction: column;
        gap: 20px;

        >p {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 30px;
        }
    }

    .recomended-activity>h3 {
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 10px;
    }

    .button-list .info-client-button {
        width: 48%;
    }

    .group-detail-one-page {
        .group-slider-info .lineHr {
            margin: 35px 0 20px;
        }

        .group-slider {
            margin-bottom: 30px;
        }
    }

    .review-slider-wrapper .section-title {
        font-size: 24px !important;
        line-height: 30px !important;
        padding: 0 15%;
    }

    .group-detail-one-page .select-your {
        margin-bottom: 0 !important;
    }

    .review-slider-wrapper .review-slider .slide-text h3 {
        font-size: 16px !important;
        line-height: 22px !important;
        color: #000000;
    }
}

.group-detail-one-page {
    input {

        &[type="text"]:focus,
        &[type="password"]:focus,
        &[type="email"]:focus,
        &[type="tel"]:focus,
        &[type="number"]:focus {
            border-color: #26C438 !important;
        }
    }

    textarea:focus {
        border-color: #26C438 !important;
    }
}

.change-label {
    color: #26C438 !important;
}

.group-detail-one-page {
    .grid:nth-child(3) {
        gap: 30px;
    }

    .call-to-action {
        width: 470px;
        padding: 26px 50px 46px;
        background: #FAFAFA;

        .section-title {
            text-align: center !important;
            margin-bottom: 30px !important;
        }
    }
}

.group-detail-all .call-to-action .section-title {
    text-align: center !important;
    margin-bottom: 30px !important;
}

.group-detail-one-page {
    .grid {
        flex-direction: column;
    }

    .cta-box {
        height: 64px;
        background: #26C438;
        display: flex;
        gap: 10px;
    }
}

.group-detail-all .cta-box {
    height: 64px;
    background: #26C438;
    display: flex;
    gap: 10px;
    max-width: 25rem;
    margin: 0 auto;
}

.group-detail-one-page .cta-box span,
.group-detail-all .cta-box span {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}

.group-detail-one-page .main-text,
.group-detail-all .main-text {
    line-height: 22px;
}

.group-detail-one-page .col-12 {
    flex-basis: unset !important;
    max-width: unset !important;
    width: 100%;
}

.group-detail-all {
    .col-12 {
        flex-basis: unset !important;
        max-width: unset !important;
        width: 100%;
        width: 50%;
    }

    .grid:nth-child(3) .col-12 {
        width: 33.33%;
    }
}

.recomended-activity__item img {
    max-width: 170px;
    height: 180px;
    object-fit: cover;
}

.group-detail-page .call-to-action {
    height: max-content;

    >.grid {
        margin: 34px 0 120px;
        gap: 40px;
    }
}

.group-detail-all .call-to-action>.grid {
    margin: 0px 0 40px;
    gap: 40px;
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.group-detail-page section.call-to-action .quote-form .submit-holder {
    margin-bottom: 30px;
}

.wpcf7-form-control-wrap.message textarea {
    height: 100px;
}

@media (max-width: 1024px) {
    .group-detail-all {

        section.call-to-action .quote-form,
        .call-to-action>.grid {
            padding: 0 15px;
        }
    }
}

@media (max-width: 1024px) {
    .group-detail-all {

        section.call-to-action .quote-form,
        .call-to-action>.grid {
            padding: 0 15px;
        }
    }
}

@media (max-width: 768px) {
    .group-detail-all {
        .call-to-action {
            padding: 0 15px;
        }

        .col-12 {
            width: 100% !important;
        }

        .call-to-action>.grid {
            flex-flow: column;
            align-items: center;
        }
    }

    .group-detail-page.group-detail-all section.call-to-action>.grid {
        padding: 0 0 20px !important;
        gap: 20px;
    }
}

.group-detail-all {
    section.call-to-action {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .call-to-action>.grid {
        max-width: 1024px;
        width: 100%;
        justify-content: center;
    }
}

