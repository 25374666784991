.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}
