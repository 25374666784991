.bike-box {
    color: $black;
    figure {
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            height: auto;
            max-height: 90%;
            display: block;
            width: auto;
        }
    }
    .bike-title {
        height: 44px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 28px;
        h4 {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.35px;
            text-align: center;
            margin-bottom: 0;
            padding: 0 14px;
            font-weight: 500;
        }
    }
    .btn {
        @include btn-outline-green;
        width: 100%;
        padding: 15px;
        font-size: 16px;
    }
    &.list-item {
        border: 1px solid #e9e9e9;
        display: inline-block;
        width: 100%;
        padding: 20px;
        margin-bottom: 30px;
        .infos-holder {
            h4 {
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}
