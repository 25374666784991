.tour-detail-page {
    .breadcrumbs {
        padding: 15px;
        margin-bottom: 30px;
        max-width: 1170px;
    }

    .container {
        max-width: 1170px;
    }

    h1 {
        font-size: 34px;
        text-align: left;
        text-transform: none;
        margin-bottom: 15px;
        line-height: normal;
    }

    h2 {
        font-size: 24px;
        text-transform: none;
        text-align: left;
        color: $dark-grey-blue;
        margin-bottom: 30px;
        line-height: normal;
    }

    hr {
        border-bottom-color: #e2e2e5;
        border-top: none;
    }

    .description {
        margin-bottom: 60px;
    }

    .activity-details {
        margin-bottom: 50px;

        i {
            display: inline-block;
        }

        i.duration {
            width: 16px;
            height: 16px;
            background: url("#{$images-path}duration.svg");
            background-size: contain;
        }

        i.availability {
            width: 16px;
            height: 16px;
            background: url("#{$images-path}availability.svg") no-repeat center center;
            background-size: contain;
        }

        i.language {
            width: 16px;
            height: 16px;
            background: url("#{$images-path}language.svg") no-repeat center center;
            background-size: contain;
        }

        i.place {
            width: 16px;
            height: 16px;
            background: url("#{$images-path}place.svg") no-repeat center center;
            background-size: contain;
        }

        i.wallet {
            width: 16px;
            height: 16px;
            background: url("#{$images-path}wallet.svg") no-repeat center center;
            background-size: contain;
        }

        li {
            padding-left: 30px;
            position: relative;
            margin-bottom: 30px;

            i {
                position: absolute;
                left: 0;
                top: 4px;
            }

            b {
                margin-right: 10px;
                font-weight: 500;
            }

            &.free {
                color: $green;
                font-weight: bold;
            }
        }
    }

    .experience-section {
        .wrapper-holder {
            margin-bottom: 30px;

            &:not(:last-child) {
                border-bottom: 1px solid $light-gray;
            }

            ul {
                li {
                    padding-left: 20px;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: #000000;
                        left: 0;
                        top: 9px;
                    }
                }
            }

            .detail-holder {
                .more {
                    display: none;
                }
            }

            .show-more-button {
                font-weight: bold;
                margin-top: 15px;
                display: block;
            }

            &.passive {
                .detail-holder {
                    .more {
                        display: block;
                    }
                }
            }
        }
    }

    .includes {
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 60px;

        ul {
            margin: 0;

            li {
                padding-left: 20px;
                position: relative;
                margin-bottom: 5px;

                &:before {
                    content: "";
                    position: absolute;
                    width: 11px;
                    height: 11px;
                    left: 0;
                    top: 8px;
                }
            }
        }

        .included {
            ul {
                li {
                    &:before {
                        background: url("#{$images-path}checkmark-blue.svg") no-repeat center center;
                        background-size: contain;
                    }
                }
            }
        }

        .excluded {
            ul {
                li {
                    &:before {
                        background: url("#{$images-path}excluded.svg") no-repeat center center;
                        background-size: contain;
                    }
                }
            }
        }
    }

    .reviews-section {
        border-top: 1px solid #e2e2e5;
        padding: 30px 0;

        .review-box {
            border-radius: 6px;
            border: solid 1px #e2e2e5;
            padding: 40px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;

            .user {
                width: 86px;
                min-width: 86px;
                margin-right: 40px;

                img {
                    border-radius: 50%;
                }

                .username {
                    font-size: 13px;
                    text-align: center;
                }
            }

            .review {
                .date-holder {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    .rating {
                        width: 60px;
                        height: 10px;
                        background: url("#{$images-path}rating.svg") no-repeat center center;
                        background-size: contain;
                        margin-right: 10px;
                    }

                    .date {
                        font-size: 12px;
                        opacity: 0.5;
                    }
                }

                h4 {
                    font-size: 15px;
                    margin-bottom: 0;

                    a {
                        color: #000;
                    }
                }

                h6 {
                    margin-bottom: 15px;
                    font-size: 12px;
                    opacity: 0.5;
                    font-weight: normal;
                    text-transform: none;
                }

                p {
                    margin: 0;
                    font-size: 13px;
                    line-height: 1.31;
                }
            }
        }
    }

    .sidebar-wrapper {
        transition: all ease 0.3s;

        @include media(">=desktop") {
            &.sticked {
                position: fixed;
                top: 0px;
                z-index: 8;
                bottom: 0;
                overflow-y: auto;
                overflow-x: hidden;
                @include scrollbar;

                .announcement-bar-active & {
                    margin-top: 30px;
                }

                .sidebar {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .sidebar {
        @include booking-sidebar;
    }

    .book-now-button-mobile {
        display: none;
        transition: all ease 0.3s;
    }

    @media only screen and (max-width: 667px) {
        .breadcrumbs {
            white-space: nowrap;
            overflow-x: hidden;
            margin-bottom: 10px;
            position: relative;

            a {
                font-size: 13px;
            }

            &:after {
                content: "...";
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: #fff;
                line-height: 47px;
                padding: 0 17px 0 8px;
            }
        }

        h1 {
            font-size: 22px;
        }

        .description {
            margin-bottom: 25px;
        }

        .book-now-button-mobile {
            display: flex;
            margin: 0;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            background-color: $green;
            color: #fff;
            box-shadow: 0px 4px 14px rgba(38, 196, 56, 0.5);
            transition: all ease 0.3s;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9999;
            width: 100%;

            .price-box {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .from {
                    display: inline-flex;
                    align-items: center;
                }

                span {
                    font-size: 15px;
                    margin-right: 9px;
                }

                b {
                    font-size: 26px;
                }

                .btn {
                    background-color: transparent;
                    border: 2px solid #fff;
                    border-radius: 6px;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: none;
                    padding: 12px 50px;
                    margin: 0;
                    height: auto;
                    color: #fff;
                }
            }
        }

        h2 {
            font-size: 18px;
            line-height: 24px;
        }

        .reviews-section {
            .review-box {
                flex-direction: column;
                padding: 20px;
                position: relative;

                .user {
                    width: auto;

                    figure {
                        display: flex;
                        align-items: flex-start;

                        img {
                            width: 86px;
                            min-width: 86px;
                            margin-right: 20px;
                        }
                    }
                }

                .review {
                    margin-top: 20px;

                    .date-holder {
                        position: absolute;
                        left: 126px;
                        top: 50px;
                        flex-direction: column;
                        align-items: flex-start;

                        .rating {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

body.tours-template-default {
    /*footer.footer {
    position: relative;
    z-index: 9;
    background-color: #fff;
  }*/

    @media only screen and (max-width: 667px) {
        #_hj_feedback_container {
            display: none;
        }
    }
}

@include media(">=desktop") {
    .tour-detail-page {
        .mobile-full-grid {
            .col-6 {
                padding-left: 0
            }
        }
    }
}