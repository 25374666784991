.events-info {
    background: $black;
    color: $white;
    padding-bottom: 30px;
    position: relative;
    overflow: hidden;
    .logo {
        @include media("<desktop") {
            display: none;
        }
        position: absolute;
        left: 50%;
        top: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
        width: 509px;
        height: 700px;
        @include media(">=large-desktop") {
            height: 1011px;
        }
    }
    @include media(">=desktop") {
        margin-bottom: 75px;
        padding-top: 70px;
    }
    @include media(">=large-desktop") {
        padding: 128px 0 85px;
    }
    .container {
        @include media(">=desktop") {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            z-index: 2;
        }
    }
    .accordion {
        @include media(">=desktop") {
            width: 67%;
            padding-left: 5px;
        }
    }
    h2,
    .h2 {
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 24px;
        @include media(">=desktop") {
            font-size: 34px;
            line-height: 42px;
            margin-bottom: 0;
        }
        @include media(">=large-desktop") {
            font-size: 40px;
            line-height: 50px;
        }
        > a {
            display: block;
            color: $white;
            transition: color 0.3s;
            @include media(">=desktop") {
                display: inline;
                &:hover {
                    color: $green;
                }
            }
        }
    }
    h3,
    .h3 {
        @include media(">=desktop") {
            text-transform: uppercase;
        }
    }
    li {
        border-bottom: 1px solid $white;
        padding: 32px 0 17px;
        @include media(">=desktop") {
            border: none;
            padding: 0 0 22px;
            h2,
            .h2 {
                text-align: left;
            }
        }
        &.active {
            h2,
            .h2 {
                > a {
                    color: $green;
                }
            }
        }
    }
    .hidden-info {
        display: none;
        padding-top: 15px;
        @include media(">=desktop") {
            display: none !important;
        }
    }
    .description {
        @include media("<desktop") {
            display: none;
        }
        width: 33%;
        padding-left: 15px;
        opacity: 0;
        &.visible {
            transition: opacity 0.5s;
            opacity: 1;
        }
    }
}
