section.faq {
    @include media("<=mobile-landscape") {
        margin: 0 9px;
    }

    .faq-holder {
        .question {
            border: 1px solid #e9e9e9;
            padding: 30px;
            margin-bottom: 30px;
            position: relative;
            cursor: pointer;
            transition: $base-transition;

            &:last-child {
                margin-bottom: 0;
            }

            @include media("<=mobile-landscape") {
                padding: 20px 60px 20px 20px;
            }

            h4 {
                margin-bottom: 0;

                @include media("<=mobile-landscape") {
                    font-size: 14px;
                }
            }

            svg {
                position: absolute;
                right: 30px;
                top: 36px;
                transition: $base-transition;

                @include media("<=mobile-landscape") {
                    top: 28px;
                }
            }

            .answer {
                opacity: 0;
                max-height: 0;
                overflow: hidden;
                transition: $base-transition;
                * {
                    margin-top: 10px;
                }
                p {
                    margin-bottom: 0;
                }
            }

            &.active {
                border: 1px solid $green;
                h4 {
                    color: $green;
                    margin-bottom: 10px;
                }
                svg {
                    transform: rotate(180deg);
                    path {
                        stroke: $green;
                    }
                }
                .answer {
                    opacity: 1;
                    max-height: initial;
                }
            }
        }
    }
}
