.position-relative {
    position: relative;
}

.d-none {
    display: none;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center !important;
}

.text-white {
    color: #fff;
}

.see-all-link {
    text-align: right;
    a {
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        i {
            margin-left: 8px;
        }
    }
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}
