.banners-wrapper {
    .banner-box {
        height: 280px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 30px;
        position: relative;
        margin-bottom: 30px;
        h3 {
            color: #fff;
            position: relative;
            z-index: 2;
            font-size: 30px;
            font-weight: bold;
            line-height: 1.13;
            letter-spacing: -0.75px;
            max-width: 350px;
            margin-bottom: 20px;
        }
        button {
            color: #fff;
            border: 3px solid #fff;
            padding: 10px 17px;
            font-size: 18px;
            font-weight: bold;
            line-height: normal;
            letter-spacing: -0.45px;
            position: relative;
            z-index: 2;
            &:hover {
                background-color: #fff;
                color: $green;
            }
        }
    }
    @media (max-width: 568px) {
        .grid {
            [class*="col-"] {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        .banner-box {
            padding: 20px;
            height: 125px;
            margin-bottom: 10px;
            h3 {
                font-size: 18px;
                line-height: 22px;
                max-width: 70%;
                margin-bottom: 15px;
            }
            a {
                font-size: 14px;
                padding: 8px;
            }
        }
    }
}
