.tour-detail-modal {
    display: none;
    filter: drop-shadow(0px 0px 80px rgba(0, 0, 0, 0.4));
    overflow-x: hidden;
    max-width: 1000px;
    padding: 0;

    .fancybox-close-small {
        opacity: 1;
    }

    .holder {
        display: flex;
        figure.featured-image {
            width: 470px;
            min-width: 470px;
            height: auto;
            display: inline-flex;
            img {
                width: 100%;
            }
        }
        .details {
            padding: 30px 20px 20px 20px;
            position: relative;
            h2 {
                font-size: 36px;
                line-height: 42px;
                text-align: left;
                text-transform: none;
                margin-bottom: 25px;
            }
            .description {
                max-height: 280px;
                overflow-y: auto;
                padding-right: 10px;
                @include scrollbar;
            }
            .duration {
                @include duration;
                opacity: 0.5;
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 25px;
            }
            .actions {
                position: absolute;
                left: 20px;
                bottom: 20px;
                right: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}
