.rental-box {
    border: 1px solid #e9e9e9;
    display: inline-block;
    width: 100%;
    color: $black;
    figure {
        position: relative;
        display: block;
        padding-bottom: 100%;
        overflow: hidden;
        a {
            display: inline-flex;
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
        .tag {
            @include tag;
        }
    }
    .infos-holder {
        padding: 15px 15px 0 15px;
        height: 112px;
        h4 {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 5px;
            min-height: 32px;
            @include twoline-clamp;
        }
        p {
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
        }
    }
    .price-holder {
        padding: 0 15px 15px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.5px;
            span {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .btn {
            @include btn-outline-green;
            font-size: 15px;
        }
    }
    @include media(">=tablet") {
        margin-bottom: 30px;
    }
    @include media("<=tablet-landscape") {
        margin-bottom: 10px;
        .infos-holder {
            padding: 10px 10px 0;
            height: auto;
            h4 {
                font-size: 12px;
                line-height: 14px;
            }
            p {
                display: none;
            }
        }
        .price-holder {
            padding: 0 10px 10px;
            .price {
                font-size: 12px;
                span {
                    font-size: 18px;
                }
            }
            .btn {
                padding: 6.5px 8px;
            }
        }
        figure {
            overflow: hidden;
            display: inline-flex;
            width: 100%;
            height: auto;
            img {
                height: intrinsic;
                width: 100%;
                max-width: initial;
            }
        }

        .price-holder {
            @include price-holder-mobile;
        }
    }
    @include media("<=mobile-landscape") {
        margin-bottom: 18px;
    }
    &:hover {
        border-color: #d4d4d4;
    }
}
