.tour-listing-page {
    .breadcrumbs {
        padding: 15px 0 20px;
        margin-bottom: 15px;
    }
    section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .container {
        max-width: 1170px;
    }
    .page-title-bar {
        @include page-title-bar;
        padding-bottom: 15px;
        margin-bottom: 15px;
        h1 {
            margin-bottom: 4px;
            text-transform: uppercase;
        }
        .description {
            margin-right: 92px;
        }
    }
    .sticky + .spacer {
        padding-top: 118px;
        @include media("<=tablet") {
            display: none;
        }
    }
    .page-links {
        position: unset;
        height: 68px;
        z-index: 99;
        margin-bottom: 30px;
        @include media("<=tablet") {
            display: none;
        }
        &.sticky {
            background-color: black;
            position: fixed;
            top: 0;
            width: 100%;
            margin-bottom: 0;
        }
        .page-link-holder {
            background-color: #000;
            width: 100%;
            ul {
                min-width: 100%;
                display: flex;
                justify-content: left;
                flex-wrap: nowrap;
                overflow: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                li {
                    padding: 20px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    a {
                        white-space: nowrap;
                        color: #fff;
                        &:hover,
                        &.active {
                            color: $green;
                        }
                    }
                }
            }
        }
    }
    section.content {
        padding: 0;
    }
    .open-filter-box-button {
        display: none;
        width: 100%;
        background-color: #fff;
        margin-bottom: 15px;
        padding: 15px;
        font-size: 14px;
        color: #000;
        font-weight: bold;
        border: 1px solid #bfbfc4;
        i {
            background: url("#{$images-path}filter-dark.svg") center center no-repeat;
            background-size: contain;
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }
    #locations {
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #000;
        .locations {
            .section-title {
                color: #fff;
                text-align: left;
                text-transform: unset;
            }
        }
    }
    .single-info-holder {
        @include single-info-holder;
    }
    section.faq {
        h2 {
            @include section-title;
        }
    }
    #populer-tours {
        h2 {
            @include section-title;
        }
        .popular-tours-wrapper {
            @include slider-wrapper;
            .arrow {
                @include slider-arrow;
                &:hover {
                    background-color: $green;
                }
            }
        }
    }
    .description-mobile {
        display: none;
    }
    @media only screen and (max-width: 667px) {
        .open-filter-box-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        [class*="col-"],
        [class*="col_"],
        [class~="col"] {
            flex-basis: 100%;
            max-width: 100%;
        }
        .breadcrumbs {
            white-space: nowrap;
            overflow-x: auto;
            margin-bottom: 10px;
            padding: 9px;
            font-size: 12px;
            a {
                font-size: 12px;
            }
            .arrow {
                height: 9px;
                margin: 0 9px;
            }
        }
        .grid.tour-list,
        .grid.rentals-list {
            margin: 0 -4.5px;
            [class*="col-"],
            [class*="col_"],
            [class~="col"] {
                flex-basis: 50%;
                max-width: 50%;
                padding: 0 4.5px;
            }
        }
        .single-info-holder {
            @include single-info-holder-mobile;
        }
        .description-mobile {
            display: block;
            background-color: #efefef;
            margin: 0 -15px;
            padding: 15px;
        }
    }

    section#advantages {
        h2 {
            @include section-title;
        }
    }
}

body.post-type-archive,
body.page-template-page-tours {
    @media only screen and (max-width: 667px) {
        #_hj_feedback_container {
            display: none;
        }
    }
}
