.advantage-boxes-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .advantage-box {
        width: 25%;
        text-align: center;
        padding: 0 15px;
        img {
            margin-bottom: 25px;
            width: 100px;
            height: 70px;
            object-fit: contain;
            object-position: center;
        }
        h4 {
            font-size: 18px;
            line-height: 22px;
            height: 44px;
            margin-bottom: 9px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p {
            opacity: 0.5;
            font-size: 14px;
            line-height: 20px;
        }
    }
    @include media("<=mobile-landscape") {
        flex-direction: column;
        .advantage-box {
            width: 100%;
            margin-bottom: 15px;
            img {
                margin-bottom: 10px;
                object-fit: contain;
            }
            h4 {
                margin-bottom: 5px;
                min-height: auto;
            }
        }
    }
}
