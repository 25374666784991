//Error section
.error-section {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    position: relative;
    z-index: 10;
    overflow: hidden;
    width: 100%;
    text-align: center;
    background-color: $black;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    @include media(">=desktop") {
        font-size: 24px;
        line-height: 30px;
    }
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        filter: grayscale(1);
        z-index: -2;
        background: {
            size: cover !important;
            position: 50% 50% !important;
        }
    }
    .decor {
        display: none;
        @include media(">=tablet") {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 440px;
            height: auto;
            z-index: -1;
        }
        @include media(">=desktop") {
            width: 540px;
        }
        @include media(">=large-desktop") {
            width: 740px;
            height: 477px;
        }
    }
    .logo {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 52px;
        @include media(">=desktop") {
            width: 118px;
            transition: width 0.45s;
            pointer-events: all;
            top: 26px;
            left: 100px;
        }
        a {
            display: block;
        }
        img {
            vertical-align: top;
            width: 100%;
            height: auto;
            transition: all 0.45s;
        }
    }
    .wrap {
        width: 100%;
        max-width: 760px;
        margin: 50px auto;
        padding: 0 15px;
        @include media(">=tablet") {
            margin: -7px auto 0;
        }
    }
    .title {
        font-size: 80px;
        line-height: 100px;
        text-transform: uppercase;
        @include media(">=tablet") {
            font-size: 150px;
            line-height: 170px;
            margin-bottom: 34px;
        }
        @include media(">=desktop") {
            font-size: 200px;
            line-height: 220px;
        }
    }
    p {
        margin-bottom: 20px;
        @include media(">=tablet") {
            margin-bottom: 45px;
        }
    }
}
