.announcement-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1052;
  background-color: $green;
  color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  * {
    margin-bottom: 0;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  & + .header {
    top: 40px;
  }

  @include media("<=mobile-landscape") {
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    width: 100%;

    a {
      margin-left: 10px;
    }
  }

  @include media(">=desktop") {
    position: absolute;
  }
}
