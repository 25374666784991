.attraction-detail-page {
    .page-title-bar {
        @include page-title-bar;
        padding-bottom: 10px;
        h1 {
            text-transform: uppercase;
        }
    }
    section {
        padding-top: 30px;
        padding-bottom: 30px;
        h2 {
            @include section-title;
        }
    }
    .section-title {
        @include section-title;
    }
    section.content {
        .content-holder {
            .more {
                display: none;
            }
            &.passive {
                .more {
                    display: block;
                }
            }
        }
        .show-more-button {
            margin-top: 15px;
            display: block;
            text-decoration: underline;
        }
    }
    section.tours,
    section.rentals {
        .tours-slider-wrapper,
        .rentals-slider-wrapper {
            .tours-slider,
            .rentals-slider {
                max-height: 0px;
                overflow: hidden;
                display: none;
                &.slick-initialized {
                    display: block;
                    max-height: unset;
                }
                position: relative;
                .slick-list {
                    margin: 0 -15px;
                }
            }
            .arrow {
                position: absolute;
                width: 50px;
                height: 50px;
                background-color: #000;
                z-index: 9;
                top: 35%;
                transform: translateY(-50%);
                opacity: 0.5;
                &.prev {
                    left: -8px;
                    background: url("#{$images-path}chevron-thin-left.svg") #000 50% 50% no-repeat;
                    background-size: 30%;
                    transform: translateY(-50%);
                }
                &.next {
                    right: -8px;
                    background: url("#{$images-path}chevron-thin-right.svg") #000 50% 50% no-repeat;
                    background-size: 30%;
                    transform: translateY(-50%);
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        .all-tours-link {
            @include all-link;
            position: absolute;
            right: 15px;
            bottom: 0px;
            a {
                color: $green;
            }
        }
    }
    section.explore-city {
        background-color: #000;
        color: #fff;
        .container {
            p {
                display: none;
            }
            .explore-box .content {
                background-color: #fff;
                border: 0;
                color: #000;
            }
        }
    }

    .do-dont-boxes {
        align-items: stretch;
        .do-dont-box {
            padding: 15px;
            border: 1px solid #e9e9e9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            align-items: center;
            background: #fff;
            img {
                height: 100px;
                object-fit: contain;
                object-position: center;
            }
            h4 {
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
            }
            p {
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 0;
                text-align: center;
            }
            .do-dont {
                width: 46px;
                height: 20px;
                background: #26c438;
                border-radius: 13px;
                margin: 15px auto;
                padding: 0 10px;
                font-size: 9px;
                line-height: 20px;
                color: #fff;
                text-align: center;
                &.dont {
                    background-color: #df0000;
                }
            }
            &:hover {
                border: 1px solid #fff;
                box-shadow: 0px 18px 47px rgba(0, 0, 0, 0.08), 0px 2.25388px 5.88513px rgba(0, 0, 0, 0.04);
            }
        }
        @include media("<=tablet") {
            .col-6 {
                flex-basis: 100%;
                max-width: 100%;
            }
            .do-dont-box {
                border: 0;
            }
        }
    }

    .right-for-you-wrapper {
        p:last-child {
            margin-bottom: 0;
        }
        @include media("<=tablet") {
            .col-12 {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }

    .page-links {
        background-color: black;
        position: fixed;
        width: 100%;
        top: 0;
        height: 0;
        z-index: 99;
        transition: height 0.25s ease;
        overflow: hidden;
        @include media("<=tablet") {
            display: none !important;
        }
        &.sticky {
            display: block;
            height: 68px;
        }
        .page-link-holder {
            background-color: #000;
            width: 100%;
            ul {
                min-width: 100%;
                display: flex;
                justify-content: left;
                flex-wrap: nowrap;
                overflow: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                li {
                    padding: 20px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    a {
                        white-space: nowrap;
                        color: #fff;
                        &:hover,
                        &.active {
                            color: $green;
                        }
                    }
                }
            }
        }
    }
}
