.pagination {
    margin: 0 0 15px;
    padding-top: 10px;
    text-align: center;
    a,
    .current,
    .extend {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        line-height: 28px;
        font-weight: normal;
        text-align: center;
        margin: 0 5px 8px;
        transition: background-color 0.3s;
    }
    .current {
        background-color: $green;
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
    .extend {
        margin: 0 10px 10px;
        @include media(">=tablet") {
            margin: 0 15px 10px;
        }
    }
    a {
        color: $black;
        position: relative;
        transition: color 0.1s;
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
        &:hover {
            @include media(">=desktop") {
                color: #fff;
                text-decoration: none;
                background-color: $green;
            }
        }
    }
    .nextpostslink,
    .previouspostslink {
        position: relative;
        width: 50px;
        height: 50px;
        background: #fff;
        text-indent: -9999px;
        transition: background-color 0.3s;
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 7px;
            height: 14px;
            background: url("#{$images-path}chevron-small-right-black.svg") 50% 50% no-repeat;
        }
        &:hover {
            background-color: $green;
            color: #fff;
        }
        &:hover::before {
            background: url("#{$images-path}chevron-small-right-white.svg") 50% 50% no-repeat;
        }
    }
    .previouspostslink {
        margin-right: 5px;
        transform: rotate(180deg);
    }
    .nextpostslink {
        margin-left: 5px;
    }
}

.wp-pagenavi {
    display: inline-block;
    vertical-align: middle;
}
