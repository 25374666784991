//Main title
.main-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    position: relative;
    min-height: 200px;
    background-color: $black;
    font-size: 16px;
    line-height: 26px;
    color: $white;
    z-index: 20;
    text-align: center;
    @include media(">=desktop") {
        min-height: 400px;
    }
    @include media(">=ultra-desktop") {
        min-height: 500px;
    }
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        filter: grayscale(1);
        z-index: -2;
        background: {
            size: cover !important;
            position: 50% 50% !important;
        }
    }

    .decor {
        display: none;
        @include media(">=desktop") {
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: auto;
        }
        &.decor-01 {
            @include media(">=desktop") {
                left: -20%;
            }
            @include media(">=large-desktop") {
                left: 39px;
            }
        }
        &.decor-02 {
            @include media(">=desktop") {
                top: 38.5%;
                right: -22%;
                height: 61.5%;
            }
            @include media(">=large-desktop") {
                top: 38.5%;
                right: -2px;
                height: 61.5%;
            }
        }
    }

    .wrap {
        max-width: 790px;
    }

    h1 {
        color: $white;
        margin-bottom: 0;
    }

    p {
        margin: 15px auto 0;
        max-width: 500px;
        @include media(">=desktop") {
            margin: 36px auto 4px;
        }
    }
}
