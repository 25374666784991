//Locations
.map {
    width: 100%;
    height: 300px;
    margin: -20px 0 40px;
    @include media(">=desktop") {
        margin: -50px 0 60px;
        height: 454px;
    }
    @include media(">=large-desktop") {
        margin-top: -75px;
    }
}

.locations-list {
    @include media(">=tablet") {
        width: 738px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    @include media(">=desktop") {
        width: auto;
        justify-content: start;
        margin: 0 -15px -5px;
    }

    li {
        width: 100%;
        max-width: 360px;
        background-color: $black;
        padding: 20px;
        color: $gray;
        font: 500 12px/20px $main-ff;
        margin-bottom: 40px;
        @include media(">=desktop") {
            max-width: 100%;
            width: calc(33.33% - 30px);
            padding: 40px;
            margin: 0 15px 30px;
        }
        @include media(">=large-desktop") {
            width: calc(25% - 30px);
        }
        &.color {
            .photo {
                filter: grayscale(0);
            }
        }
    }

    .photo {
        display: block;
        height: 200px;
        filter: grayscale(1);
        margin: -20px -20px 0;
        background: {
            size: cover !important;
            position: 50% 50% !important;
        }
        @include media(">=desktop") {
            height: 268px;
            margin: -40px -40px 0;
        }
    }

    .line {
        display: block;
        position: relative;
        height: 4px;
        background-color: $green;
        margin-bottom: 30px;
        margin-right: -20px;
        @include media(">=desktop") {
            margin-right: -40px;
        }
        @include media(">=large-desktop") {
            margin-bottom: 36px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green;
            z-index: 1;
        }

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $white;
            z-index: 2;
        }
    }

    h3,
    .h3 {
        text-transform: uppercase;
        color: $white;
        text-align: left;
        margin-bottom: 10px;
        @include media(">=desktop") {
            margin-bottom: 20px;
        }
    }

    address {
        font: 16px/26px $main-ff;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 10px;
        @include media(">=desktop") {
            margin-bottom: 20px;
        }
    }

    p {
        margin-bottom: 0;
        text-transform: uppercase;
    }

    a.phone {
        display: inline-block;
        margin-top: 10px;
        font: 700 19px/26px $main-ff;
        @include media(">=desktop") {
            transition: color 0.35s;
            &:hover {
                color: $white;
            }
        }
    }
}
