//Info steps
.info-steps {
    padding-bottom: 30px;
    @include media(">=desktop") {
        padding-bottom: 60px;
    }

    li {
        margin-bottom: 40px;
        @include media(">=desktop") {
            text-align: left;
            margin-bottom: 174px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        &:first-child {
            &:before {
                @include media(">=desktop") {
                    top: calc(100% - 20px);
                    height: 210px;
                    background: url("#{$images-path}info-steps-01.svg") 50% 50% no-repeat;
                    transform: rotateY(0deg) !important;
                }
            }
        }
        &:nth-child(even) {
            @include media(">=desktop") {
                .info {
                    padding: 40px 80px 0;
                    order: 10;
                }
            }
            @include media(">=large-desktop") {
                .info {
                    padding: 51px 50px 0 190px;
                }
            }
        }
        &:nth-child(odd) {
            &:before {
                @include media(">=desktop") {
                    transform: rotateY(180deg);
                }
            }
        }
        &:last-child {
            @include media(">=desktop") {
                margin-bottom: 50px;
            }
            &:before {
                display: none;
            }
        }

        &:before {
            @include media(">=desktop") {
                content: "";
                position: absolute;
                top: 100%;
                height: 190px;
                width: 100%;
                background: url("#{$images-path}info-steps-02.svg") 50% 50% no-repeat;
                background-size: contain;
                z-index: 20;
            }
        }
        &:after {
            content: "";
            clear: both;
            display: block;
        }

        .info {
            max-width: 480px;
            margin: 0 auto 25px;
            @include media(">=desktop") {
                width: calc(100% - 550px);
                max-width: 100%;
                padding: 80px 80px 0;
                margin: 0;
            }
            @include media(">=large-desktop") {
                padding: 98px 80px 0 160px;
            }
        }

        h2,
        .h2 {
            position: relative;
            display: inline-block;
            z-index: 10;
            text-align: left;
            margin-bottom: 20px;
            &:before {
                content: counter(counterName);
                counter-increment: counterName;
                color: $green;
                opacity: 0.5;
                position: absolute;
                left: -10px;
                top: -20px;
                font-size: 70px;
                line-height: 70px;
                z-index: -1;
                @include media(">=desktop") {
                    font-size: 160px;
                    line-height: 160px;
                    top: -81px;
                    left: -36px;
                }
            }
        }

        .image {
            display: block;
            max-width: 550px;
            height: 220px;
            margin: 0 auto;
            background: {
                size: cover !important;
                position: 50% 50% !important;
            }
            @include media(">=tablet") {
                height: 280px;
            }
            @include media(">=desktop") {
                width: 550px;
                height: 337px;
                margin: 0;
                align-self: flex-end;
            }
        }
    }

    .button {
        margin-bottom: 0;
    }
}
