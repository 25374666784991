.info-boxes-wrapper {
    .info-box {
        margin-bottom: 50px;
        padding: 26px 15px;
        transition: $base-transition;
        a {
            display: inline-block;
            width: 100%;
            color: $black;
            button {
                background: transparent;
                border: none;
                font-weight: 500;
                font-size: 16px;
                display: block;
                margin: auto;
                border-bottom: 1px solid $green;
                padding: 3px 0;
            }
        }
        figure {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }
        h4 {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
            text-align: center;
        }
        p {
            opacity: 0.5;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            margin-bottom: 20px;
        }
        &:hover {
            box-shadow: 0px 18px 47px rgba(0, 0, 0, 0.08), 0px 2.25388px 5.88513px rgba(0, 0, 0, 0.04);
        }
    }
    @include media("<=mobile-landscape") {
        .col-6 {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}
