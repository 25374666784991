.explore-city {
    h2 {
        text-align: left;
        text-transform: none;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    a{
        color: #000;
        &:hover {
            text-decoration: none;
        }
    }
    .explore-box {
        display: flex;
        flex-direction: column;
        .image figure {
            width: 100%;
            position: relative;
            display: block;
            padding-bottom: 100%;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
            }
        }
        .content {
            border: 1px solid #e9e9e9;
            padding: 15px;
            .title {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                height: 44px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                margin-bottom: 15px;
            }
            .user figure {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    object-fit: cover;
                    overflow: hidden;
                    border: 1px solid #e9e9e9;
                }
                span {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 12px;
                    margin-left: 15px;
                }
            }
            a {
                text-decoration: none;
                color: #000;
            }
            &:hover {
                border-color: #D4D4D4;
            }
        }
    }
    @include media("<=mobile-landscape") {
        h2 {
            font-size: 24px;
            line-height: 30px;
        }
        .grid {
            overflow: auto;
            margin: 0 -10px 0 0;
            flex-wrap: nowrap;
        }
        .col-6 {
            flex-basis: 70%;
            max-width: 70%;
            padding-left: 0;
            padding-right: 10px;
        }
    }
}
