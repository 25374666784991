//Filters holder
.filter-holder {
    padding-bottom: 30px;
    @include media(">=tablet") {
        width: 100%;
        max-width: 630px;
        margin: 0 auto 30px;
    }
    @include media(">=desktop") {
        padding-top: 44px;
        max-width: 100%;
    }

    .container {
        @include media(">=desktop") {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            &:after {
                display: none;
            }
        }
        @include media(">=large-desktop") {
            max-width: 1300px;
        }
    }

    .title {
        position: relative;
        z-index: 10;
        padding-left: 40px;
        font: 700 14px/30px $main-ff;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-right: 50px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green;
            z-index: -1;
        }

        svg {
            position: absolute;
            top: 9px;
            left: 6px;
            width: 18px;
            height: 12px;
            z-index: 2;
        }
    }

    .wrap {
        margin-right: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    label {
        font: 700 14px/30px $main-ff;
        text-transform: uppercase;
        margin: 0 10px 0 0;
    }

    select {
        margin-bottom: 0;
    }

    .jcf-select {
        @include media(">=desktop") {
            margin-bottom: 35px;
        }
    }
    .clear {
        font-size: 14px;
        font-weight: 500;
        @include media(">=desktop") {
            display: block;
            text-align: right;
        }
    }
}
