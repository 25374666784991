.main-nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 1px 3px 13px 0 rgba(0,0,0,0.06) inset;

  @include media(">=tablet") {
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
  }

  @include media(">=desktop") {
    padding: 0;
  }

  > li {
    display: block;
    width: 100%;

    @include media(">=tablet") {
      display: inline-block;
      margin-left: 0;
      margin-right: 30px;
    }

    @include media(">=desktop") {
      margin-left: 25px;
      margin-right: 0;
    }

    &.menu-item-has-children {
      &.hovered {

        > a {
          color: $green;
          position: relative;

          &:after {
            width: 100%;
          }
        }

        .dropdown-menu {
          visibility: visible;
          opacity: 1;
        }

      }

      > a {
        position: relative;
        padding-right: 15px;

        @include media(">=tablet") {
          padding-right: 0;
        }

        &:before {
          content: "";
          background: url("#{$images-path}arrow-bottom-thin-black.svg") no-repeat center center;
          background-size: contain;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 9px;
          height: 5px;

          @include media(">=tablet") {
            display: none;
          }
        }
      }
    }

    &:first-child {
      > .dropdown-menu {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    &.current-menu-item {
      > a {
        color: $green;

        &:after {
          width: 100%;
        }
      }
    }

    &.city-menu {        
        .dropdown-menu {
            max-height: calc(100vh - 175px);
            overflow-y: auto;
            overflow-x: hidden;
        }        
    }

    > a {
      color: $black;
      display: block;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      white-space: nowrap;
      text-transform: uppercase;
      padding: 20px 0;
      position: relative;
      transition: $base-transition;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        bottom: 0;
        border-top: 2px solid $green;
        transition: $base-transition;
      }

      &:hover {
        color: $green;

        &:after {
          width: 100%;
        }
      }
    }
  }

  .dropdown-menu {
    display: none;
    visibility: hidden;
    background: #fff;
    z-index: 50;
    position: initial;
    left: 0;
    width: 100%;

    @include media(">=tablet") {
      position: absolute;
    }

    @include media(">=desktop") {
      background: #F3F3F3;
      display: block;
      top: 100%;
      padding: 16px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }

    a {
      color: $black;
    }

  }

  .sub-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    width: 100%;

    @include media(">=tablet") {
      flex-direction: row;
      align-items: center;
    }

    li {

      @include media("<tablet") {
        &:not(:last-child) {
          border-bottom: 1px solid #E9E9E9;
        }
      }

      @include media(">=tablet") {
        margin: 0 15px;
      }

      @include media(">=desktop") {
        margin: 0 30px;
      }

      a {
        font-size: 14px;
        font-weight: 500;
        display: block;
        padding: 15px 0;

        @include media(">=desktop") {
          display: inline-block;
          padding: 0;
        }

        &:hover {
          color: $green;
        }
      }
    }
  }

}
