.faq {
    @include media("<=mobile-landscape") {
        margin: 0 -9px;
    }

    .breadcrumbs {
        border-bottom: none;
        margin-bottom: 0;
    }

    .hero {
        padding: 30px 27px;
        margin-bottom: 30px;
        background-size: cover;
        background-position: center right;

        h1 {
            margin-top: 87px;
            color: #fff;
            font-size: 36px;
            line-height: 42px;
            text-transform: none;
            margin-bottom: 22px;
        }

        form {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 500px;
            margin: 0 auto 22px;
            border: 3px solid #fff;
            background: rgba(0, 0, 0, 0.3);
            position: relative;

            input {
                margin-bottom: 0;
                border: none;
                font-size: 18px;
                background: transparent;
                color: #fff;
                @include placeholder {
                    color: #fff;
                }
            }

            button {
                height: 60px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                min-width: 60px;
                background: transparent;
                border: none;
                border-right: 3px solid #fff;
            }

            .form-results {
                position: absolute;
                left: -3px;
                right: -3px;
                top: 63px;
                background-color: #000;
                z-index: 9;
                display: none;

                ul {
                    margin-bottom: 0;

                    li {
                        a {
                            color: #fff;
                            padding: 18px 22px;
                            display: block;

                            &:hover {
                                background-color: #333333;
                            }
                        }
                    }
                }
            }
        }

        .divider {
            svg {
                width: 100%;
                height: auto;
            }
        }

        @include media("<=mobile-landscape") {
            padding: 30px 10px 10px;
            margin-bottom: 0;
            h1 {
                margin-top: 0;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
            }
            form {
                max-width: 320px;

                button {
                    height: 40px;
                }

                input {
                    height: 40px;
                    font-size: 16px;
                }

                .form-results {
                    top: 43px;
                }
            }
        }
    }

    .faq-holder {
        .categories {
            border: 1px solid #e9e9e9;

            li {
                a {
                    color: $black;
                    display: block;
                    padding: 19px 20px;
                    position: relative;
                    line-height: 19px;
                    font-weight: 500;
                    background: url("#{$images-path}chevron-right.svg") no-repeat center right 10px;
                    background-size: 9px;

                    &.active {
                        color: #fff;
                        background: url("#{$images-path}chevron-right-white.svg") $green no-repeat center right 10px;
                        background-size: 9px;
                    }
                }

                &:not(:last-child) {
                    a {
                        border-bottom: 1px solid #e9e9e9;
                    }
                }
            }
        }

        .active-category-mobile-title {
            display: none;
        }

        @include media("<=mobile-landscape") {
            .categories {
                margin-bottom: 0;
                li {
                    a {
                        font-size: 18px;
                        padding: 15px;
                    }
                }
            }
            .active-category-mobile-title {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 10000;
                background-color: #000;
                padding: 20px;
                text-align: center;
                a {
                    position: absolute;
                    left: 15px;
                }
                span {
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                    color: #fff;
                }
            }
        }
    }

    .questions-holder {
        .question-box {
            padding: 28px 0;

            .title-wrapper {
                font-weight: 500;
                position: relative;
                cursor: pointer;

                .icon {
                    position: absolute;
                    right: 0;
                    top: 12px;

                    &:before {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 17px;
                        height: 3px;
                        background-color: $black;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        right: 7px;
                        top: -7px;
                        width: 3px;
                        height: 17px;
                        background-color: $black;
                        transition: all ease 0.3s;
                    }
                }
            }

            .answer {
                padding-top: 10px;
                display: none;

                p {
                    margin-bottom: 0;
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid #e9e9e9;
            }

            &:first-child {
                padding-top: 0;
            }

            &.active {
                .title-wrapper {
                    .icon {
                        &:after {
                            opacity: 0;
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }

        @include media("<=mobile-landscape") {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9999;
            background-color: #fff;
            padding: 85px 10px 20px;
            overflow-y: scroll;
            display: none;
            .question-box {
                padding: 20px 0;
            }
        }
    }
}
