.tours-carousel {
    position: relative;
    .slick-slider {
        max-height: 0px;
        overflow: hidden;
        display: none;
        &.slick-initialized {
            display: block;
            max-height: unset;
        }
    }
    .slick-track {
        @include media("<tablet") {
        }
    }
    .carousel {
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        .slide {
            height: 100%;
            @include media(">=tablet") {
                width: 25%;
            }
            @include media("<tablet") {
                width: 50%;
                padding-left: 15px;
                padding-right: 0;
            }
        }
        @include media("<tablet") {
            .slick-list {
                padding-right: 15px;
            }
        }
    }
    .arrow {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #000;
        z-index: 9;
        top: 35%;
        transform: translateY(-50%);
        opacity: 0.5;
        &.prev {
            left: -25px;
            background: url("#{$images-path}chevron-thin-left.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            transform: translateY(-50%);
        }
        &.next {
            right: -25px;
            background: url("#{$images-path}chevron-thin-right.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            transform: translateY(-50%);
        }
        &:hover {
            opacity: 1;
        }
        @include media("<=tablet") {
            display: none;
        }
    }
}