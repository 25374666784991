.city-box {
    position: relative;
    transition: $base-transition;
    margin-bottom: 30px;

    @include media("<=tablet-landscape") {
        margin-bottom: 15px;
    }

    figure {
        display: flex;
        img {
            width: 100%;
            height: auto;
        }
    }

    h4 {
        height: 128px;
        position: relative;
        bottom: 0;
        background-color: $black;
        left: 0;
        width: 100%;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 65px;
        font-weight: bold;
        font-size: 24px;
        color: #fff;
        &:before {
            content: "";
            position: absolute;
            background: url("#{$images-path}city-box-line.svg") no-repeat center center;
            width: 320px;
            height: 30px;
            top: -14px;
            right: 0;
        }
        @include media("<=tablet-landscape") {
            padding-left: 25px;
            font-size: 18px;
            height: 80px;
        }
    }

    &:hover {
        @include hover-box-shadow;
    }
}
