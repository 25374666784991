button {
    box-shadow: none;
    font-family: $main-ff;
    white-space: nowrap;
    &[type="submit"] {
        height: 49px;
        font-size: 16px;
        text-transform: uppercase;
        padding-left: 45px;
        padding-right: 45px;
    }
}

.btn-green {
    background-color: $green;
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 9.5px 20px;
}

.btn-black {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    padding: 9.5px 20px;
}
