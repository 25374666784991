.city-dropdown {
  cursor: pointer;
  font-weight: bold;
  right: 23px;

  @include media(">=tablet") {
    border-left: 1px solid #E9E9E9;
    margin-left: 15px;
    padding-left: 15px;
    position: relative;
    right: initial;
  }

  &:hover {
    @include media(">=tablet") {
      .city-menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  .selected {
    text-transform: uppercase;
    position: relative;
    padding-right: 20px;
    z-index: 52;
    white-space: nowrap;

    #selected-city-text {
      display: none;

      @include media(">=tablet") {
        display: block;
      }
    }

    &:after {
      content: " ";
      position: absolute;
      right: 0;
      top: 50%;
      width: 18px;
      height: 24px;
      background: url("../images/pin-black.svg") no-repeat center center;
      background-size: contain;
      transform: translateY(-50%);

      @include media(">=tablet") {
        width: 9px;
        height: 5px;
        background: url("../images/arrow-bottom-thin-black.svg") no-repeat center center;
      }
    }

    &.opened {

      &:after {
        background: url("../images/pin-green-filled.svg") no-repeat center center;
        background-size: contain;
      }

      & + .city-menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  .city-menu {
    position: absolute;
    z-index: 51;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 22px 45px rgba(0, 0, 0, 0.07), 0 2.75474px 5.6347px rgba(0, 0, 0, 0.035);
    padding: 20px;
    top: 100%;
    right: 0;
    left: initial;

    @include media(">=tablet") {
      top: -12px;
      left: -1px;
      padding: 62px 15px 15px 16px;
      width: 270px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 52px;
        background: #FAFAFA;
      }
    }

    ul {
      margin: 0;
      display: none;

      @include media(">=tablet") {
        display: block;
      }

      li {
        @include media("<=tablet") {
          &:not(:last-child) {
            border-bottom: 1px solid #E9E9E9;
          }
        }
      }

      li.active {
        a {
          color: $green;
          background: url("../images/check.svg") no-repeat center left;
        }
      }
    }
  }

  .city-menu-title {
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 1px;
    color: #848487;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  a {
    color: $black;
    font-size: 14px;
    line-height: 52px;
    font-weight: normal;
    display: block;
    text-transform: uppercase;
    padding-left: 26px;

    @include media(">=tablet") {
      line-height: 40px;
    }

    &:hover {
      color: $green;
    }

  }

  &.city-selected {

  }
}
