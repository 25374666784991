@mixin bg($posX: null, $posY: null, $sizeX: null, $sizeY: null) {
    background: {
        repeat: no-repeat;
        position: $posX $posY;
        size: $sizeX $sizeY;
    }
}

@mixin pos($pos: null, $top: null, $left: null, $bottom: null, $right: null) {
    position: $pos;
    right: $right;
    bottom: $bottom;

    @if ($top =="center" and $left =="center") {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    @if ($top =="center" and $left !="center") {
        top: 50%;
        transform: translateY(-50%);
    }

    @if not($top =="center") {
        top: $top;
    }

    @if ($left =="center" and $top !="center") {
        left: 50%;
        transform: translateX(-50%);
    }

    @if not($left =="center") {
        left: $left;
    }
}

@mixin flex($fd: null, $jc: null, $ai: null, $fw: null, $ac: null) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: $fd;
    flex-direction: $fd;
    justify-content: $jc;
    align-items: $ai;
    flex-wrap: $fw;
    align-content: $ac;
}

// EXTEND

// clearfix
%clearfix {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

// hide text
%hide-text {
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
}

// full width
%full-width {
    margin-right: -9999px;
    margin-left: -9999px;
    padding-right: 9999px;
    padding-left: 9999px;
}

// reset for list
%reset-list {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

// clear bullets for li
%reset-list-bullets {
    padding-left: 0;

    &:before {
        display: none;
    }
}

// text ellipsis
%ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// justify nav
%justify {
    text-align: justify;

    >* {
        display: inline-block;
        text-align: left;
        vertical-align: top;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }
}

// icomoon
%icon {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    font-family: "icomoon", serif;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// MIXIN

// size
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

// circle
@mixin circle($width, $height: $width) {
    border-radius: 50%;
    width: $width;
    height: $height;
}

// resizable font
@mixin resizable-font($font-size: 14, $line-height: 20, $parent-font-size: 10) {
    line-height: decimal-round(($line-height / $font-size), 3) * 1em;
    font-size: decimal-round(($font-size / $parent-font-size), 3) * 1em;
}

// placeholder

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        opacity: 1;
        @content;
    }

    &::-moz-placeholder {
        opacity: 1;
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

// retina
@mixin retina($image, $extension, $width, $height) {
    background: url("../images/" + $image + "." + $extension) no-repeat;
    width: $width;
    height: $height;

    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        background-image: url("../images/" + $image + "-2x" + "." + $extension);
        background-size: $width $height;
    }
}

// linear gradient
@mixin linear-gradient($from-color, $to-color) {
    background-color: $to-color;
    background: -webkit-linear-gradient(top, $from-color 0%, $to-color 100%);
    background: linear-gradient(to bottom, $from-color 0%, $to-color 100%);
}

@mixin animate($dur) {
    transition: all $dur + "s" linear 0s;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value: on) {
    @if $value ==on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// round decimals function
@function decimal-round($number, $digits: 3, $mode: round) {
    $n: 1;

    // $number must be a number
    @if type-of($number) !=number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }

    // $digits must be a unitless number
    @if type-of($digits) !=number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    }

    @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }

    @for $i from 1 through $digits {
        $n: $n * 10;
    }

    @if $mode ==round {
        @return round($number * $n) / $n;
    }

    @else if $mode ==ceil {
        @return ceil($number * $n) / $n;
    }

    @else if $mode ==floor {
        @return floor($number * $n) / $n;
    }

    @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}

@mixin btn-outline-green {
    border: 2px solid $green;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.45px;
    text-align: center;
    padding: 6px 12px 7px;
    display: block;
    transition: all ease 0.3s;

    &:hover {
        background-color: $green;
        color: #fff;
    }

    @media (max-width: 500px) {
        font-size: 13px;
    }
}

@mixin tag {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $green;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: normal;
    padding: 11px 20px;

    @media (max-width: 500px) {
        font-size: 12px;
        padding: 8px;
    }
}

@mixin all-link {
    text-align: right;

    a {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.45px;
        color: #ffffff;

        i {
            margin-left: 11px;
        }
    }

    @include media("<=tablet") {
        text-align: center;
        margin-top: 30px;
    }
}

@mixin twoline-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: auto;
}

@mixin threeline-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: auto;
}

@mixin price-holder-mobile {
    padding: 20px 10px 10px;
    position: relative;

    .price {
        position: absolute;
        right: 10px;
        top: -5px;
        font-size: 12px;

        span {
            font-size: 16px;
        }
    }

    .btn {
        width: 100%;
    }
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #e9e9e9;
    }

    &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #e0e0e0;
    }
}

@mixin page-title-bar {
    padding-bottom: 44px;
    margin-top: 15px;
    margin-bottom: 30px;
    background: url("#{$images-path}title-divider.svg") bottom left no-repeat;
    background-size: 100%;

    h1 {
        font-size: 48px;
        text-align: left;
        text-transform: none;
        margin-bottom: 15px;
    }

    .city {
        color: rgba(0, 0, 0, 0.5);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;

        i {
            width: 15px;
            height: 19px;
            background: url("#{$images-path}pin.svg") bottom left no-repeat;
            display: inline-block;
            background-size: contain;
            margin-right: 6px;
        }
    }

    .description {
        margin-bottom: 0;

        p {
            margin-bottom: 0;
        }
    }

    .tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tags {
            display: flex;
            align-items: center;

            .badges-list {
                display: flex;
                align-items: center;
                margin: 0;

                li {
                    display: inline-flex;
                    margin-right: 5px;
                }
            }
        }

        .links {
            display: flex;
            align-items: center;
            margin: 0 95px 0 0;

            li {
                margin-left: 10px;
            }
        }
    }

    @include media("<=mobile-landscape") {
        margin-bottom: 15px;
        background: url("#{$images-path}title-divider-mobile.svg") bottom left no-repeat;
        background-size: 100%;

        h1 {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 5px;
            max-width: 75%;
        }

        .city {
            font-size: 12px;
        }

        .description {
            font-size: 12px;
            line-height: 14px;
        }

        .tags-wrapper {
            flex-direction: column;
            align-items: flex-start;

            ul.links {
                margin-top: 20px;

                li {
                    margin: 0 10px 0 0;

                    a {
                        font-size: 12px;
                        white-space: nowrap;
                        padding: 9.5px 11px;
                    }
                }
            }
        }
    }
}

@mixin section-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 43px;
    text-align: left;
    margin-bottom: 20px;
    text-transform: none;

    @include media("<=mobile-landscape") {
        font-size: 18px;
        line-height: 25px;
    }

    @include media("<=tablet") {
        font-size: 24px;
        line-height: 30px;
    }
}

@mixin booking-sidebar {
    .starting {
        width: 260px;
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        h5 {
            text-transform: none;
            font-size: 14px;
            font-weight: normal;
            margin: 0 10px 0 0;
        }

        .price {
            font-size: 34px;
            font-weight: 500;
            letter-spacing: -0.4px;

            span {
                font-size: 24px;
            }
        }
    }

    .book-online {
        height: 55vh;
        width: 100%;
        background-color: white;

        @include media(">=desktop") {
            height: calc(100vh - 200px);
            width: 277px;
        }

        .fareharbor-calendar-wrap {
            border: 1px solid #e2e2e5;
        }

        .btn-green {
            width: 100%;
            display: block;
            text-align: center;
        }

        iframe {
            width: 100%;
            height: 100%;
            border: none;
            border-top: 1px solid #e2e2e5;
            padding-top: 1rem;
            overflow: hidden;
        }
    }

    @include media("<=mobile-landscape") {
        margin-bottom: 20px;
        width: 100%;

        .starting {
            width: 100%;
            display: none;
        }

        &.sticked {
            position: initial;
            width: 100%;
        }
    }
}

@mixin mobile-half-grid {
    margin: 0 -9px;

    &>[class*="col-"] {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0 9px;
    }
}

@mixin mobile-full-grid {
    margin: 0 -9px;

    &>[class*="col-"] {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0 9px;
    }
}

@mixin single-info-holder {
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 30px;
    padding-bottom: 30px;

    h2,
    h3 {
        text-align: left;
        font-weight: bold;
        font-size: 30px;
        line-height: 43px;
        margin-bottom: 20px;
        text-transform: none;
    }

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ul {
        li {
            position: relative;
            padding-left: 25px;
            padding-bottom: 0;
            line-height: 2;

            &:before {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $green;
                left: 0;
                top: 13px;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &:last-of-type,
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

@mixin duration {
    font-size: 15px;
    color: $dark-grey-blue;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    opacity: 0.3;

    i {
        width: 16px;
        height: 16px;
        background: url("#{$images-path}clock.svg");
        background-size: contain;
        margin-right: 5px;
    }

    b {
        font-weight: 500;
        margin-right: 5px;
    }
}

@mixin single-info-holder-mobile {
    margin-bottom: 15px;
    padding-bottom: 5px;

    &:last-of-type {
        padding-bottom: 5px;
        border-bottom: 1px solid #e9e9e9;
    }

    h2 {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
        position: relative;

        &:after {
            width: 16px;
            height: 16px;
            position: absolute;
            content: "";
            right: 6px;
            top: 4px;
            background: url("#{$images-path}plus.svg") #fff no-repeat center center;
        }
    }

    .content {
        font-size: 12px;
        line-height: 14px;
        display: none;

        ul {
            padding: 0;
            margin-bottom: 20px;

            li {
                padding-left: 20px;

                &:before {
                    width: 6px;
                    height: 6px;
                    top: 10px;
                }
            }
        }
    }

    &.active {
        h2:after {
            background: url("#{$images-path}minus.svg") #fff no-repeat center center;
        }

        .content {
            display: block;
        }
    }
}

@mixin hover-box-shadow {
    box-shadow: 0px 18px 47px rgba(0, 0, 0, 0.08), 0px 2.25388px 5.88513px rgba(0, 0, 0, 0.04);
}

@mixin slider-wrapper {
    position: relative;

    .slick-slider {
        max-height: 0px;
        overflow: hidden;
        display: none;

        &.slick-initialized {
            display: block;
            max-height: unset;
        }

        .slick-list {
            margin: 0 -15px;

            .slide {
                padding: 0 15px;
            }

            @include media("<=tablet") {
                margin: 0 -5px;

                .slide {
                    padding: 0 5px 0;
                }
            }
        }
    }
}

@mixin slider-arrow {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #000;
    z-index: 9;
    top: 35%;
    transform: translateY(-50%);
    opacity: 0.5;

    &.prev {
        left: -25px;
        background: url("#{$images-path}chevron-thin-left.svg") #000 50% 50% no-repeat;
        background-size: 30%;
        transform: translateY(-50%);
    }

    &.next {
        right: -25px;
        background: url("#{$images-path}chevron-thin-right.svg") #000 50% 50% no-repeat;
        background-size: 30%;
        transform: translateY(-50%);
    }

    &:hover {
        opacity: 1;
    }

    @include media("<=tablet") {
        width: 30px;
        height: 30px;

        &.prev {
            left: -15px;
        }

        &.next {
            right: -15px;
        }
    }
}