.cta-modal {
    display: none;
    border-top: 10px solid $green;
    background-color: $black;
    color: #fff;
    padding: 45px 80px 30px 80px;
    filter: drop-shadow(0px 0px 80px rgba(0, 0, 0, 0.4));
    overflow-x: hidden;

    .fancybox-close-small {
        opacity: 1;
    }

    .title-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            margin-bottom: 28px;
        }
    }

    .modal-body {
        form {
            input,
            select {
                height: 35px;
                border: 1px solid #fff;
                background-color: $black;
                color: #fff;
                width: 100%;
                max-width: 100%;
                padding: 5px 15px;
            }

            textarea {
                border: 1px solid #fff;
                background-color: $black;
                color: #fff;
                max-height: 140px;
            }

            .btn-green.btn-block {
                width: 100%;
            }
        }

        .form-note {
            font-size: 10px;
            line-height: 13px;
            text-align: center;
            margin-top: 45px;
        }
    }

    &#call-modal {
        max-width: 500px;

        input[type="tel"] {
            background: url("#{$images-path}phone-white.svg") no-repeat left 10px center;
            padding-left: 33px;
        }
    }

    .btn-green {
        border: none;
        font-size: 16px;
    }

    @include media("<=mobile-landscape") {
        padding: 100px 25px 25px 25px;
        width: 100%;
        min-height: 100%;
        border-top-width: 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 50px;
            background-color: $green;
        }
        .fancybox-close-small {
            top: 3px;
        }
        .title-bar {
            font-size: 20px;
            img {
                margin-bottom: 20px;
            }
        }
        &#quote-modal {
            .grid {
                @include mobile-full-grid;
            }
        }
    }
}
