.gift-card-page {
    .breadcrumbs {
        margin-bottom: 0;
    }
    .hero {
        background-size: cover;
        background-repeat: no-repeat center center;
        padding: 275px 150px 42px 150px;
        position: relative;
        margin-bottom: 30px;
        &:before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 35.94%, #000000 69.79%, #000000 95%);
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .content-holder {
            position: relative;
            z-index: 2;
            h1 {
                font-size: 46px;
                color: #fff;
                text-align: center;
                max-width: 640px;
                margin: 0 auto 30px auto;
            }
            .info-boxes-wrapper {
                .info-box {
                    color: #fff;
                    p {
                        opacity: 1;
                    }
                }
            }
            .btn {
                @include btn-outline-green;
                display: inline-block;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .gift-cards-wrapper {
        .gift-card {
            text-align: center;
            margin-bottom: 30px;
            figure {
                display: flex;
            }
            h4 {
                font-size: 20px;
                margin-top: 30px;
                margin-bottom: 14px;
            }
            p {
                font-size: 34px;
                font-weight: bold;
                margin-bottom: 14px;
            }
            .btn {
                @include btn-outline-green;
                display: inline-block;
            }
        }
    }
    @include media("<=mobile-landscape") {
        .hero {
            padding: 110px 30px 30px 30px;
            margin: 0 -9px 20px -9px;
            background-size: 100%;
            &:before {
                top: 0;
                bottom: auto;
                height: 180px;
            }
            &:after {
                background-color: #000;
                content: " ";
                position: absolute;
                left: 0;
                right: 0;
                top: 180px;
                bottom: 0;
            }
            .content-holder {
                h1 {
                    font-size: 20px;
                    line-height: 24px;
                }
                .info-boxes-wrapper {
                    .info-box {
                        figure {
                            height: auto;
                            margin-bottom: 10px;
                        }
                        h4 {
                            margin-bottom: 7px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .gift-cards-wrapper {
            .grid {
                flex-wrap: nowrap;
                padding-left: 10px;
                overflow-x: auto;
                .col-8 {
                    width: 160px;
                    min-width: 160px;
                    padding: 0 10px;
                }
                .gift-card {
                    figure {
                        margin-bottom: 9px;
                    }
                    h4 {
                        font-size: 12px;
                        line-height: 14px;
                        margin: 9px;
                    }
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 9px;
                    }
                }
            }
        }
        section.faq {
            padding-top: 15px;
            border-top: 1px solid #e9e9e9;
        }
    }
}
