.checkout-bikes {
    h2 {
        text-align: left;
        text-transform: none;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    figure {
        text-align: center;
    }
    .title {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    .description {
        margin-bottom: 30px;
    }
    .btn {
        @include btn-outline-green;
        font-size: 16px;
        padding: 13px 32px 13px;
    }
    @include media("<=mobile-landscape") {
        h2 {
            font-size: 24px;
            line-height: 30px;
        }
        .col-7,
        .col-17 {
            flex-basis: 100%;
            max-width: 100%;
        }
        .btn {
            margin: auto;
        }
    }
}
