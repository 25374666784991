.locations {
    .locations-wrapper {
        position: relative;
        .slick-slider {
            max-height: 0px;
            overflow: hidden;
            display: none;
            &.slick-initialized {
                display: block;
                max-height: unset;
            }
        }
        .slick-list {
            margin: 0 -15px;
        }
        .slide {
            padding: 0 15px;
        }
        .slick-dots {
            li {
                margin: 0 15px;
                height: 11px;
                &.slick-active {
                    button {
                        background-color: #fff !important;
                        border: 1px solid #fff;
                    }
                }
            }
            button {
                width: 11px;
                height: 11px;
                background-color: #000;
                border: 1px solid #fff;
            }
        }

        .arrow {
            position: absolute;
            width: 50px;
            height: 50px;
            background-color: #fff;
            z-index: 9;
            top: 43%;
            transform: translateY(-50%);
            box-shadow: 0 0 5px #0001;
            &.prev {
                left: -25px;
                background: url("#{$images-path}chevron-thin-left-green.svg") #fff 50% 50% no-repeat;
                background-size: 30%;
                transform: translateY(-50%);
            }
            &.next {
                right: -25px;
                background: url("#{$images-path}chevron-thin-right-green.svg") #fff 50% 50% no-repeat;
                background-size: 30%;
                transform: translateY(-50%);
            }
            &.prev:hover {
                background: url("#{$images-path}chevron-thin-left.svg") $green 50% 50% no-repeat;
                background-size: 30%;
            }
            &.next:hover {
                background: url("#{$images-path}chevron-thin-right.svg") $green 50% 50% no-repeat;
                background-size: 30%;
            }
            @include media("<=mobile-landscape") {
                width: 30px;
                height: 30px;
                top: 15%;
                &.prev {
                    left: -15px;
                }
                &.next {
                    right: -15px;
                }
            }
        }
    }
}

.location-box {
    background-color: $black;
    color: #fff;
    display: flex;
    flex-direction: row;
    border: 1px solid #333333;
    .figure {
        flex-basis: 50%;
        overflow: hidden;
        position: relative;
        display: block; 
        padding-bottom: 72%;       
        @include media("<tablet") {
            padding-bottom: 56%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .infos-holder {
        position: relative;
        flex-basis: 50%;
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        h4 {
            margin-bottom: 20px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
        }
        .details {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;            
            .hours {
                font-size: 14px;
                p {
                    margin-bottom: 20px;
                }
            }
            .address {
                .address-text {
                    display: flex;
                    color: #bfbfc4;
                    margin-bottom: 15px;
                    i {
                        float: left;
                        width: 40px;
                        flex-shrink: 0;
                    }
                }
                .phone {
                    display: block;
                    color: #bfbfc4;
                    i {
                        float: left;
                        width: 40px;
                        flex-shrink: 0;
                    }
                }
            }
        }
        &::before {
            content: "";
            position: absolute;
            background: url("../images/green-line-top.svg") no-repeat center center;
            width: 30px;
            height: 370px;
            bottom: -2px;
            left: -15px;
            z-index: 1;
        }
    }
    .btn-green {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        i {
            margin-right: 15px;
        }
    }
    @include media("<=mobile-landscape") {
        flex-direction: column;
        height: 590px;
        figure {
            overflow: hidden;
            img {
                height: 190px;
                flex-basis: 100%;
            }
        }
        .infos-holder {
            flex-basis: 100%;
            height: 400px;
            &::before {
                content: "";
                position: absolute;
                background: url("../images/green-line-right.svg") no-repeat center center;
                width: 340px;
                height: 30px;
                left: -3px;
                top: -15px;
                z-index: 1;
            }
        }
    }
}

.bike-template .location-box {
    flex-direction: column;
    height: 590px;
    figure {
        overflow: hidden;
        img {
            height: 190px;
            flex-basis: 100%;
        }
    }
    .infos-holder {
        flex-basis: 100%;
        height: 400px;
        padding: 20px 20px 20px 20px !important;
        &::before {
            content: "";
            position: absolute;
            background: url("../images/green-line-right.svg") no-repeat center center;
            width: 340px;
            height: 30px;
            left: -3px;
            top: -15px;
            z-index: 1;
        }
    }
}
