.ul-icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  &.angle-right {
    background-image: url("#{$images-path}chevron-right.svg");
    width: 9px;
    height: 13px;
  }
  &.pin-white {
    background-image: url("#{$images-path}pin-white.svg");
    width: 16px;
    height: 21px;
  }
  &.pin-gray {
    background-image: url("#{$images-path}pin-gray.svg");
    width: 16px;
    height: 21px;
  }
  &.email {
    background-image: url("#{$images-path}email.svg");
    width: 30px;
    height: 19px;
  }
  &.phone {
    background-image: url("#{$images-path}phone.svg");
    width: 22px;
    height: 22px;
  }
  &.phone-gray {
    background-image: url("#{$images-path}phone-gray.svg");
    width: 22px;
    height: 22px;
  }
  &.chat {
    background-image: url("#{$images-path}chat-white.svg");
    width: 16px;
    height: 16px;
  }
  &.trash-gray {
    background-image: url("#{$images-path}trash-gray.svg");
    width: 16px;
    height: 16px;
  }
}