.warning-box {
    display: flex;
    align-items: center;
    background-color: #fec053;
    color: #fff;
    font-weight: 500;
    margin-bottom: 35px;
    i {
        background: url("#{$images-path}lightbulb.svg") #ffb32f no-repeat center center;
        width: 100px;
        height: 100px;
        margin-right: 25px;
    }
    p {
        margin-bottom: 0;
    }
    @include media("<=mobile-landscape") {
        margin-bottom: 20px;
        i {
            width: 80px;
            height: 75px;
            margin-right: 20px;
            background-size: 27px;
        }
        .content {
            padding-right: 20px;
            font-size: 12px;
            line-height: 14px;
        }
    }
}
