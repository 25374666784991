.filter-box {
    form {
        display: flex;
        flex-direction: column;
        .selected-filter {
            border: 1px solid $green;
            margin-bottom: 30px;
            order: 0;
            h4 {
                color: $green;
                font-size: 18px;
                font-weight: 500;
                padding: 15px;
                margin-bottom: 0;
            }
            .selected-item {
                padding: 10px 15px;
                border-bottom: 1px solid #e9e9e9;
                &:last-child {
                    border-top: none;
                }
                .filter {
                    font-size: 12px;
                    line-height: 18px;
                    color: #848487;
                }
                .name {
                    font-size: 15px;
                    line-height: 26px;
                    font-weight: 500;
                    .trash {
                        float: right;
                        cursor: pointer;
                    }
                }
            }
            @include media("<=tablet") {
                display: none;
            }
        }
        .filter-block-holder {
            order: 1;
            .filter-block {
                h4 {
                    font-size: 18px;
                    font-weight: 500;
                    padding: 15px;
                    margin-bottom: 0;
                    border: 1px solid #e9e9e9;
                    border-bottom: none;
                    @include media("<=tablet") {
                        border: none;
                    }
                }
                fieldset {
                    border: 1px solid #e9e9e9;
                    border-top: none;
                    background-color: #ffffff;
                    margin: 0 0 30px;
                    @include media(">=desktop") {
                        // max-height: 357px;
                        overflow-y: auto;
                    }
                    @include media("<=tablet") {
                        border: none;
                    }
                    .checkbox-item,
                    .radio-item {
                        padding: 12px 15px;
                        input {
                            display: none;
                        }
                        label {
                            margin-bottom: 0;
                            cursor: pointer;
                            display: flex;
                            align-items: flex-start;
                            flex-direction: row-reverse;
                            justify-content: flex-end;
                            font-size: 16px;
                            margin: 0;
                            vertical-align: middle;
                            text-transform: none;
                            line-height: 26px;
                            &.checked {
                                font-weight: 500;
                            }
                            span {
                                border: 1px solid #c9c9c9;
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                                display: inline-block;
                                float: left;
                                position: relative;
                                top: 2px;
                                flex-shrink: 0;
                            }
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid #e5e5e5;
                        }
                    }
                    .checkbox-item {
                        input:checked {
                            & + span {
                                border-color: $green;
                                background: url("#{$images-path}checkmark-white.svg") $green no-repeat center center;
                                background-size: 70%;
                            }
                        }
                    }
                    .radio-item {
                        label {
                            span {
                                border-radius: 50%;
                            }
                        }
                        input:checked {
                            & + span {
                                border: none;
                                background: url(../images/radio-green.svg) no-repeat center 0;
                            }
                        }
                    }
                }
            }
        }
        .button-holder {
            order: 2;
            button.green {
                width: 100%;
                padding: 6px 38px;
                height: 50px;
                text-transform: none;
                font-weight: bold;
                border: 3px solid $green;
                background-color: #fff;
                color: $green;
                margin-bottom: 0;
                font-size: 16px;
                &:hover {
                    background-color: $green;
                    color: #fff;
                }
            }
        }
        .daterange-picker, .text-item {
            padding: 15px;
            input {
                margin: 0;
                padding: 5px 8px;
                letter-spacing: -0.25px;
            }
        }
    }
    .title-bar {
        display: none;
        order: 0;
    }
    @media only screen and (max-width: 667px) {
        position: fixed;
        z-index: 998;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0vh;
        padding: 50px 0;
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px);
        transition: height ease 0.3s;
        background-color: #fff;
        &.active {
            height: 99vh;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
        }
        form {
            height: 100%;
        }
        .title-bar {
            position: fixed;
            z-index: 999;
            top: 0;
            left: 0;
            right: 0;
            padding: 13px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .close-filterbox {
                background: url("#{$images-path}close-black.svg") center center no-repeat;
                width: 16px;
                height: 16px;
            }
            h3 {
                font-size: 24px;
                font-weight: bold;
                margin: 0;
                width: 80px;
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateX(-40px);
            }
        }
        form {
            h4 {
                background-color: #fff;
            }
            .filter-block-holder {
                height: 100%;
                overflow-y: auto;
            }
            .button-holder {
                bottom: 0px;
                left: 0px;
                right: 0px;
                padding: 15px;
                position: fixed;
                background-color: #fff;
                box-shadow: 0px -0.5985091924667358px 5.32008171081543px 0px #00000007;
                box-shadow: 0px -2.010267734527588px 17.869047164916992px 0px #0000000b;
                box-shadow: 0px -9px 80px 0px #00000012;
            }
            button.green {
                height: 35px;
                font-size: 14px;
                background-color: $green !important;
                color: #fff !important;
            }
            fieldset.special-interests {
                margin-bottom: 100px;
            }
        }
    }
}
