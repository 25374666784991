//Delivery
.concierge-holder {
    .container {
        @include media(">=large-desktop") {
            max-width: 1300px;
        }
    }

    .call-holder {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        @include media(">=desktop") {
            font-size: 20px;
        }
        & + .book-list {
            padding-top: 20px;
            @include media(">=desktop") {
                padding-top: 50px;
            }
            .container {
                @include media("<desktop") {
                    padding-left: 0;
                    padding-right: 0;
                }
                @include media(">=large-desktop") {
                    max-width: 970px;
                }
            }
        }
        .h3,
        h3 {
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        .tel {
            display: inline-block;
            position: relative;
            z-index: 10;
            padding-left: 40px;
            color: $black;
            margin-bottom: 0;
            @include media(">=desktop") {
                margin-bottom: 0;
                transition: color 0.35s;
                &:hover {
                    color: $green;

                    svg {
                        animation: ring 3s 0.7s ease-in-out infinite;
                    }
                }
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: -2px;
                width: 29px;
                height: 29px;
                border-radius: 50%;
                background-color: $green;
                z-index: -1;
                @include media(">=desktop") {
                    top: 0;
                }
            }

            svg {
                width: 13px;
                height: 15px;
                position: absolute;
                top: 5px;
                left: 8px;
                z-index: 2;
                @include media(">=desktop") {
                    top: 7px;
                }
            }
        }
        p {
            margin-bottom: 20px;
        }
    }
}

.top-heading {
    font: 700 18px/22px $main-ff;
    color: $black;
    margin-bottom: 40px;
    padding: 25px 0;
    text-align: center;
    @include media(">=desktop") {
        text-align: left;
        max-width: 1010px;
        margin: 0 auto 80px;
        padding: 44px 0 4px;
        font-size: 24px;
        line-height: 32px;
    }
    @include media(">=large-desktop") {
        margin-bottom: 120px;
    }

    strong {
        color: $green;
    }

    p {
        margin-bottom: 10px;
        @include media(">=desktop") {
            margin-bottom: 32px;
        }
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }
    1% {
        transform: rotate(30deg);
    }
    3% {
        transform: rotate(-28deg);
    }
    5% {
        transform: rotate(34deg);
    }
    7% {
        transform: rotate(-32deg);
    }
    9% {
        transform: rotate(30deg);
    }
    11% {
        transform: rotate(-28deg);
    }
    13% {
        transform: rotate(26deg);
    }
    15% {
        transform: rotate(-24deg);
    }
    17% {
        transform: rotate(22deg);
    }
    19% {
        transform: rotate(-20deg);
    }
    21% {
        transform: rotate(18deg);
    }
    23% {
        transform: rotate(-16deg);
    }
    25% {
        transform: rotate(14deg);
    }
    27% {
        transform: rotate(-12deg);
    }
    29% {
        transform: rotate(10deg);
    }
    31% {
        transform: rotate(-8deg);
    }
    33% {
        transform: rotate(6deg);
    }
    35% {
        transform: rotate(-4deg);
    }
    37% {
        transform: rotate(2deg);
    }
    39% {
        transform: rotate(-1deg);
    }
    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}
