.main {
    flex-shrink: 0;
    flex-grow: 1;
}

.wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    @include flex(column);
    flex-shrink: 0;
    flex-grow: 1;
    min-height: 100vh;
    height: 100%;
    padding-top: 112px;
    
    @include media(">=desktop") {
        padding-top: 195px;
    }
    
    @media (min-width: 768px) and (max-width: 1199px) {
        padding-top: 217px;
    }
}

.header,
.footer {
    flex-shrink: 0;
}

.container {
    padding: 0 15px;
    position: relative;
    @include media(">=tablet-landscape") {
        max-width: 980px;
        margin: 0 auto;
    }
    @include media(">=desktop") {
        max-width: 1170px;
    }
    @include media("<=mobile-landscape") {
        padding: 0 15px;
    }
    &:after {
        content: "";
        clear: both;
        display: block;
    }
}

.mobile-full-grid {
    @include media("<=mobile-landscape") {
        @include mobile-full-grid;
    }
}
.mobile-half-grid {
    @include media("<=mobile-landscape") {
        @include mobile-half-grid;
    }
}
