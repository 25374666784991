/* select */
.jcf-select {
    display: inline-block;
    vertical-align: top;
    position: relative;
    border: none;
    background-color: rgba(191, 191, 196, 0.25);
    min-width: 150px;
    height: 60px;
    width: 100%;
    margin-bottom: 20px;
}

.jcf-select.jcf-drop-active {
    border-bottom: none;
}

.jcf-select select {
    z-index: 1;
    left: 0;
    top: 0;
}

.jcf-select .jcf-select-text {
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
    display: block;
    font-size: 16px;
    line-height: 18px;
    padding: 12px 14px;
    color: $black;
    @include media(">=desktop") {
        padding: 21px 14px;
    }
    .jcf-option-hideme {
        color: #9598a3;
    }
}

.jcf-select .jcf-select-opener {
    position: absolute;
    text-align: center;
    width: 26px;
    height: 20px;
    right: 6px;
    top: 15px;
    cursor: pointer;
    &:after {
        content: "";
        position: absolute;
        right: 10px;
        top: 10px;
        width: 15px;
        height: 10px;
        background: url("#{$images-path}arrow-down.svg") 50% 50% no-repeat;
        transition: transform 0.3s;
    }
}

body > .jcf-select-drop {
    position: absolute;
    margin: -1px 0 0;
    z-index: 9999;
}

body > .jcf-select-drop.jcf-drop-flipped {
    margin: 1px 0 0;
}

.jcf-select .jcf-select-drop {
    position: absolute;
    margin-top: 0px;
    z-index: 9999;
    top: 100%;
    left: -1px;
    right: -1px;
}

.jcf-select .jcf-drop-flipped {
    bottom: 100%;
    top: auto;
}

.jcf-select.jcf-compact-multiple {
    max-width: 220px;
}

.jcf-select.jcf-compact-multiple .jcf-select-opener:before {
    display: inline-block;
    padding-top: 2px;
    content: "...";
}

.jcf-select-drop .jcf-select-drop-content {
    //border: 1px solid #f00;
}

.jcf-select-drop.jcf-compact-multiple .jcf-hover {
    background: none;
}

.jcf-select-drop.jcf-compact-multiple .jcf-selected {
    background: #e6e6e6;
    color: #000;
}

.jcf-select-drop.jcf-compact-multiple .jcf-selected:before {
    display: inline-block;
    content: "";
    height: 4px;
    width: 8px;
    margin: -7px 5px 0 -3px;
    border: 3px solid #777;
    border-width: 0 0 3px 3px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')";
}

/* multiple select styles */
.jcf-list-box {
    overflow: hidden;
    display: inline-block;
    border: 1px solid #b8c3c9;
    min-width: 200px;
    margin: 0 15px;
}

/* select options styles */
.jcf-list {
    display: inline-block;
    vertical-align: top;
    position: relative;
    background-color: $white;
    line-height: 20px;
    font-size: 16px;
    width: 100%;
    border: 1px solid #e2e2e5;
    border-top: 2px solid #000;
}

.jcf-list .jcf-list-content {
    vertical-align: top;
    display: inline-block;
    overflow: auto;
    width: 100%;
}

.jcf-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.jcf-list ul li {
    overflow: hidden;
    display: block;
    padding: 0;
}

.jcf-list .jcf-overflow {
    overflow: auto;
}

.jcf-list .jcf-option {
    overflow: hidden;
    cursor: default;
    display: block;
    padding: 15px 28px;
    color: $black;
    min-height: 14px;
    height: 1%;
    border-bottom: 1px solid #e2e2e5;
}

.jcf-list .jcf-disabled {
    background: #fff !important;
    color: #aaa !important;
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
    background: $black;
    color: $white;
}

.jcf-list .jcf-optgroup-caption {
    white-space: nowrap;
    font-weight: bold;
    display: block;
    padding: 5px 9px;
    cursor: default;
    color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
    padding-left: 30px;
}

/* common styles */
.jcf-disabled {
    background: #ddd !important;
}

.jcf-select-drop .jcf-option-hideme {
    display: none;
}

//.jcf-focus, .jcf-focus * {border-color: #f00 !important;}
