//Delivery
.delivery-holder {
    padding: 25px 0;
    text-align: center;
    @include media(">=desktop") {
        padding: 44px 0 4px;
    }

    .container {
        @include media(">=large-desktop") {
            max-width: 1300px;
        }
    }

    .info-steps + .h1 {
        @include media(">=desktop") {
            margin-bottom: 54px;
            margin-top: 6px;
        }
    }
}
