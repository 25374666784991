.available-bikes {
    margin-bottom: 30px;
    h2 {
        @include section-title;
    }

    .description {
        margin-bottom: 40px;
    }

    .bikes-wrapper {
        .bike-box {
            position: relative;
            margin-bottom: 30px;

            .price {
                display: block;
                text-align: center;
                margin-bottom: 10px;
                font-weight: 500;
                b {
                    font-size: 24px;
                }
            }
        }
    }
    @include media("<=mobile-landscape") {
        .bikes-wrapper {
            .grid {
                flex-wrap: nowrap;
                overflow-x: auto;
                margin: 0;
                .col-4,
                .col-6 {
                    max-width: initial;
                    width: 130px;
                    padding: 0;
                    margin-right: 10px;
                }
                .bike-box {
                    width: 130px;
                    margin-bottom: 0;
                    figure {
                        height: 75px;
                        margin-bottom: 9px;
                        img {
                            height: 75px;
                            width: auto;
                        }
                    }
                    .bike-title {
                        height: 28px;
                        align-items: flex-start;
                        overflow: hidden;
                        h4 {
                            font-size: 12px;
                            padding: 0;
                        }
                    }
                    .price {
                        font-size: 12px;
                        b {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}
