// variables

// colors
$white: #fff;
$black: #0A0A0A;
$medium-black: #1f1f1f;
$gray: #bfbfc4;
$light-gray: #e9e9e9;
$medium-gray: #6e6e6e;
$dark-gray: #404043;
$slate-gray: #9598a3;
$green: #26c438;
$silver: #efeff1;
$red: #ff2800;
$dark-grey-blue: #2f4858;

$main-ff: "Gotham A", "Gotham B", "Gotham", sans-serif;

// grid settings
$gl-colCount: 24;
$gl-gridName: grid;
$gl-colName: col;
$gl-attributeName: class;
$gl-gutter: 30px; // Total left + right
$gl-gutter-vertical: 0;
$gl-mq-width: "min-width";
$gl-mq-list: (
    xs: 320px,
    sm: 768px,
    md: 1200px,
    lg: 1800px,
);

// media settings
$breakpoints: (
    mobile-landscape: 568px,
    tablet: 768px,
    tablet-landscape: 1024px,
    desktop: 1200px,
    medium-desktop: 1440px,
    large-desktop: 1800px,
    ultra-desktop: 2400px,
);

$images-path: "../images/";

$base-transition: all ease 0.3s;

body {
    position: relative;
    font: 400 14px/24px $main-ff;
    color: $black;
    background: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media(">=desktop") {
        font-size: 16px;
        line-height: 26px;
        transition: background-color 0.3s;
    }

    &.no-scroll {
        overflow: hidden;
    }

    &.overlay {
      .menu-overlay {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 10;
      }
        @include media(">=desktop") {
            &:after {
               display: none;
            }
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 700;
}

h1,
h2,
.h1,
.h2 {
    @include media(">=desktop") {
        text-align: center;
    }

    > span {
        display: inline-block;
        position: relative;
        padding: 0 20px;
        @include media(">=desktop") {
            padding: 0 25px;
        }

        &:before,
        &:after {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $green;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include media(">=desktop") {
                width: 10px;
                height: 10px;
            }
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

h1,
.h1 {
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    margin-bottom: 30px;
    @include media(">=desktop") {
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 38px;
    }
    @include media(">=large-desktop") {
        font-size: 50px;
        line-height: 60px;
    }
}

h2,
.h2 {
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
    margin-bottom: 25px;
    @include media(">=desktop") {
        font-size: 30px;
        line-height: 40px;
    }
    @include media(">=large-desktop") {
        font-size: 40px;
        line-height: 50px;
    }
}

h3,
.h3 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
    @include media(">=large-desktop") {
        font-size: 24px;
        line-height: 30px;
    }
}

h4,
.h4 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 20px;
    @include media(">=large-desktop") {
        font-size: 20px;
        line-height: 26px;
    }
}

h5,
.h5 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 15px;
    @include media(">=desktop") {
        font-size: 16px;
        line-height: 24px;
    }
    @include media(">=large-desktop") {
        font-size: 18px;
        line-height: 28px;
    }
}

h6,
.h6 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
}

img {
    max-width: 100%;
}

p {
    margin-bottom: 20px;
}

a {
    color: $green;
    text-decoration: none;
    outline: none;

    &.more {
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding-right: 20px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 12px;
            background: url("#{$images-path}arrow-right-green.svg") 50% 50% no-repeat;
            transition: margin-right 0.3s;
            margin-right: -5px;
        }
    }
}

body.no-touch {
    a.more {
        &:hover {
            &:after {
                margin-right: -15px;
            }
        }
    }
}

ul {
    margin-bottom: 30px;

    .content & {
        margin-bottom: 20px;

        li {
            padding: 0 0 20px 20px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                border-radius: 50%;
                width: 5px;
                height: 5px;
                background: $black;
            }
        }
    }
}

ol {
    counter-reset: counterName;

    .content & {
        li {
            position: relative;
            padding: 0 0 20px 30px;
            @include media(">=desktop") {
                padding-left: 42px;
            }

            &:before {
                content: counter(counterName, decimal-leading-zero) ".";
                counter-increment: counterName;
                position: absolute;
                left: 0;
                top: 3px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                @include media(">=desktop") {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        ul,
        ol {
            margin: 0;
            padding-left: 20px;
        }
    }
}

// forms

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea {
    width: 100%;
    margin: 0 0 30px;
    background-color: #fff;
    color: $black;
    border: 1px solid #e9e9e9;
    padding: 5px 15px;
    font: 16px/30px $main-ff;
    height: 60px;
    vertical-align: middle;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    transition: border-color 0.35s;
    @include placeholder {
        color: $slate-gray;
    }

    &:focus {
        border-color: $black;
    }

    &.wpcf7-not-valid {
        color: $red;
        border-bottom-color: $red;
    }
}

textarea {
    resize: none;
    min-height: 100px;
    height: auto;
    @include media(">=desktop") {
        min-height: 156px;
        padding: 13px 15px;
    }
}

select {
    display: block;
    width: 100%;
    margin: 0 0 30px;
    border: 1px solid $light-gray;
    padding: 5px 20px;
    line-height: 30px;
    font-size: 16px;
    height: 60px;
    vertical-align: middle;
    background: $white;
    outline: none;
    appearance: none;
    background: url("#{$images-path}select-arrow.svg") no-repeat center right 16px;

    &:focus {
    }
}

.button,
input[type="submit"],
button {
    background: transparent;
    color: $green;
    font-weight: bold;
    cursor: pointer;
    transition: all ease 0.3s;
}

input[type="checkbox"] {
    display: inline-block;
    margin: 0 0 20px;
    vertical-align: middle;
}

input[type="radio"] {
    display: inline-block;
    margin: 0 0 20px;
    vertical-align: middle;
}

label {
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin: 0 0 10px;
    vertical-align: middle;
    text-transform: uppercase;
}

blockquote {
    margin: 25px 0;
    padding: 0 0 0 40px;
    position: relative;
    font-weight: 500;
    @include media(">=desktop") {
        font-size: 20px;
        line-height: 32px;
        padding-left: 90px;
    }

    &:before {
        content: '"';
        position: absolute;
        left: 0;
        top: 0;
        font-size: 100px;
        font-weight: 700;
        color: $green;
        @include media(">=desktop") {
            top: 32px;
        }
    }

    p {
        margin: 0;
    }

    q {
        quotes: none;

        &:before,
        &:after {
            quotes: none;
        }
    }

    cite {
        display: block;
    }
}

table {
    margin: 0 0 20px;

    th,
    td {
        border: 1px solid $medium-gray;
        padding: 5px;
    }
}
