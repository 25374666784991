.about-us-page {
    padding-bottom: 0;

    .breadcrumbs {
        margin-bottom: 0;
        border-bottom: none;
    }

    .content-block {
        display: flex;
        align-items: center;
        @include media("<=mobile-landscape") {
            flex-direction: column;
            padding-top: 10px;
        }

        figure {
            width: 50%;
            max-width: 50%;
            min-width: 50%;
            display: flex;
            @include media("<=mobile-landscape") {
                width: 100%;
                max-width: 100%;
            }
        }

        .content-wrapper {
            padding: 0 85px;
            @include media("<=mobile-landscape") {
                padding: 30px;
            }
        }

        &.right {
            flex-direction: row-reverse;
            @include media("<=mobile-landscape") {
                flex-direction: column;
            }
        }
    }
}
