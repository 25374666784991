.rich-homepage-wrapper {

    .page-title-bar {
        @include page-title-bar;
        padding-bottom: 10px;
        h1 {
            text-transform: uppercase;
        }
    }

    @include media("<=tablet") {
        display: flex;
        flex-direction: column;
        section.content {
            order: 9;
        }
    }
}
.page-template-page-home-rich {
    section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    section.content {
        h2 {
            @include section-title;
        }
        .content-holder {
            .more {
                display: none;
            }
            &.passive {
                .more {
                    display: block;
                }
            }
        }
        .show-more-button {
            margin-top: -20px;
            display: block;
            text-decoration: underline;
        }
    }
    section.faq {
        h2 {
            @include section-title;
        }
    }
    section.bikes {
        h2 {
            @include section-title;
        }
        .col-4 {
            .bike-box figure {
                margin-top: 40px;
            }
        }
        .col-4:nth-child(-n + 6) {
            .bike-box figure {
                margin-top: 0;
            }
        }
    }
    section.rentals {
        h2 {
            @include section-title;
        }
        .all-tours-link {
            @include all-link;
            a {
                color: $green;
            }
        }
    }
    #locations {
        background-color: #000;
        .locations {
            .section-title {
                color: #fff;
                text-align: left;
                text-transform: unset;
            }
        }
    }
    section.events {
        background-color: #000000;
        margin-top: 10px;
        margin-bottom: 10px;
        h2 {
            @include section-title;
            color: #fff;
        }
        .all-events-link {
            @include all-link;
        }
    }
    section.attractions {
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #000;
        h2 {
            @include section-title;
            color: #fff;
        }
        .attraction-box {
            opacity: 0.75;
            @include media("<=tablet") {
                opacity: 0.9;
            }
            &:hover {
                opacity: 1;
            }
            a {
                color: #fff;
                text-decoration: none;
            }
            figure {
                position: relative;
                img {
                    width: 270px;
                    height: 360px;
                    object-fit: cover;
                    object-position: center;
                    @include media("<=tablet") {
                        width: 170px;
                        height: 190px;
                    }
                }
                .title {
                    position: absolute;
                    bottom: 30px;
                    left: 0;
                    right: 0;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 34px;
                    text-align: center;
                    @include media("<=tablet") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
        @include media("<=tablet") {
            .grid {
                overflow: auto;
                margin: 0 -10px 0 0;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                .col-6 {
                    padding: 0 5px;
                    width: 170px;
                    -ms-flex-preferred-size: auto;
                    flex-basis: auto;
                    max-width: inherit;
                }
            }
        }
    }
    section.tours {
        h2 {
            @include section-title;
        }
        .tours-slider-wrapper {
            .tours-slider {
                max-height: 0px;
                overflow: hidden;
                display: none;
                &.slick-initialized {
                    display: block;
                    max-height: unset;
                }
                position: relative;
                .slick-list {
                    margin: 0 -15px;
                }
            }
            .arrow {
                position: absolute;
                width: 50px;
                height: 50px;
                background-color: #000;
                z-index: 9;
                top: 35%;
                transform: translateY(-50%);
                opacity: 0.5;
                &.prev {
                    left: -8px;
                    background: url("#{$images-path}chevron-thin-left.svg") #000 50% 50% no-repeat;
                    background-size: 30%;
                    transform: translateY(-50%);
                }
                &.next {
                    right: -8px;
                    background: url("#{$images-path}chevron-thin-right.svg") #000 50% 50% no-repeat;
                    background-size: 30%;
                    transform: translateY(-50%);
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        .all-tours-link {
            @include all-link;
            position: absolute;
            right: 15px;
            bottom: 0px;
            a {
                color: $green;
            }
        }
    }
    .fancybox-container {
        .fancybox-bg {
            opacity: 0.2;
        }
    }
    @include media("<=tablet-landscape") {
        .main {
            padding-top: 0;
        }
        section.tours {
            .tour-box {
                .price-holder {
                    .price {
                        font-size: 12px;
                        span {
                            font-size: 18px;
                        }
                    }
                    .btn {
                        padding: 9px 12px;
                    }
                }
            }
            .all-tours-link {
                position: absolute;
                right: 10px;
                top: 0;
                margin-top: 4px;
                a {
                    font-size: 14px;
                    color: $green;
                    display: inline-flex;
                    align-items: center;
                    i {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    @include media("<=tablet") {
        .main {
            padding-top: 0;
            .container {
                padding: 0 10px;
                .page-title-bar {
                    margin-bottom: 30px;
                }
            }
        }
        section {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        section.content {
            margin-bottom: 0;
            h2 {
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0;
                margin-bottom: 10px;
            }
            p {
                margin-top: 20px;
            }
        }
        section.bikes {
            margin-bottom: 0;
            h2 {
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0;
                margin-bottom: 10px;
            }
            .grid {
                overflow: auto;
                margin: 0 -10px 0 0;
                flex-wrap: nowrap;
                .col-4:nth-child(-n + 6) {
                    .bike-box {
                        margin-bottom: 0;
                    }
                }
                .col-4 {
                    width: 157px;
                    flex-basis: auto;
                    max-width: inherit;
                    padding: 0;
                    margin-right: 10px;
                }
                .bike-box {
                    figure {
                        padding: 0 5px;
                        height: 80px;
                        margin-bottom: 15px;
                        margin-top: 20px !important;
                    }
                }
            }
        }
        section.tours {
            h2 {
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0;
                margin-bottom: 10px;
            }
            .tour-box {
                display: block;
                height: auto;
                margin-bottom: 10px;
                figure {
                    height: auto;
                    width: 100%;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .infos-holder {
                    padding: 10px 10px 0;
                    h4 {
                        padding: 0;
                        font-size: 12px;
                        line-height: 14px;
                        @include threeline-clamp;
                        margin-bottom: 8px;
                        min-height: 42px;
                    }
                    .duration {
                        margin-bottom: 12px;
                        font-size: 12px;
                    }
                }
                .price-holder {
                    @include price-holder-mobile;
                }
            }
        }
    }
    @media (max-width: 500px) {
        section.rentals {
            margin-bottom: 0;
            .grid {
                padding: 0 10px;
                .col-6 {
                    padding: 0 5px;
                    flex-basis: 50%;
                    max-width: 50%;
                }
            }
        }
    }
    @media (max-width: 500px) {

        .main {
            padding-bottom: 0;
        }
        section.events {
            position: relative;
            h2 {
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0;
            }
            .grid {
                padding: 0;
                overflow: auto;
                margin: 0 -10px 0 0;
                flex-wrap: nowrap;
                .col-6 {
                    padding: 0 5px;
                    width: 270px;
                    flex-basis: auto;
                    max-width: inherit;
                    .event-box {
                        margin-bottom: 0;
                    }
                }
            }
            .all-events-link {
                position: absolute;
                right: 10px;
                top: 0;
                margin-top: 4px;
                a {
                    font-size: 14px;
                    color: $green;
                    display: inline-flex;
                    align-items: center;
                    i {
                        margin-left: 8px;
                    }
                }
            }
        }
        section.tours {
            position: relative;
            .tours-slider-wrapper {
                .tours-slider {
                    .slick-list {
                        margin: 0 -5px;
                        .col-6 {
                            padding: 0 5px 0;
                            .tour-box {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .arrow {
                    width: 30px;
                    height: 30px;
                    &.prev {
                        left: 0;
                    }
                    &.next {
                        right: 0;
                    }
                }
            }
        }
    }
}
