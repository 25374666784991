section.bike-prices {
    h2 {
        @include section-title;
    }
    .prices-wrapper {
        display: flex;
        align-items: flex-start;
        .duration {
            background: #000;
            width: 170px;
            color: #fff;
            h5 {
                height: 150px;
                padding: 20px;
                display: inline-flex;
                align-items: flex-end;
                font-size: 20px;
                margin: 0;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    padding: 6.5px 20px;
                    border-top: 1px solid rgba(201, 201, 201, 0.4);
                }
            }
        }
    }
    .bikes-slider-wrapper {
        position: relative;
        width: calc(100% - 170px);
        .bikes-slider {
            width: 100%;
            max-width: 1000px;
            .bike-box {
                background: #f7f7f7;
                border-right: 1px solid #e9e9e9;
                border-top: 1px solid #e9e9e9;
                transition: all ease 0.3s;
                figure {
                    padding: 13px;
                    height: auto;
                    background-color: #fff;
                    img {
                        height: 70px;
                        width: auto;
                    }
                }
                .bike-title {
                    align-items: flex-start;
                    height: 54px;
                    margin-bottom: 0;
                    background-color: #fff;
                    overflow: hidden;
                    h4 {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                ul.prices {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li {
                        padding: 6.5px 20px;
                        border-top: 1px solid rgba(201, 201, 201, 0.4);
                        font-weight: 500;
                        text-align: center;
                    }
                }
                &:hover {
                    background: #ffffff;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
                }
            }
        }
        .arrow {
            width: 40px;
            height: 40px;
            background-color: #000;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 50%;
            margin-top: -14px;
            z-index: 9;
            &.left {
                background-image: url("#{$images-path}chevron-left-white.svg");
                background-size: 30%;
                left: 0;
            }
            &.right {
                background-image: url("#{$images-path}chevron-right-white.svg");
                background-size: 30%;
                right: 0;
            }
            &:hover {
                background-color: $green;
                box-shadow: 0px 1.596024513244629px 2.7930428981781006px 0px #0000001b;
                box-shadow: 0px 5.360713958740234px 9.38124942779541px 0px #00000028;
                box-shadow: 0px 24px 42px 0px #00000042;
            }
            @include media("<=mobile-landscape") {
                width: 30px;
                height: 30px;
                &.prev {
                    left: -15px;
                }
                &.next {
                    right: -15px;
                }
            }
        }
        &.static {
            width: 100%;
            .bikes-slider {
                display: flex;
                .slide {
                    width: 100%;
                }
            }
            .arrow {
                display: none;
            }
        }
    }
    @include media("<=mobile-landscape") {
        margin-bottom: 28px;
        .prices-wrapper {
            position: relative;
            &.activate-shadow:after {
                content: " ";
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 24px;
                background: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
            }
            .duration {
                width: 105px;
                h5 {
                    height: 83px;
                    padding: 8px;
                    font-size: 12px;
                    align-items: center;
                }
                ul {
                    li {
                        padding: 6.5px 8px;
                        font-size: 12px;
                    }
                }
            }
        }
        .bikes-slider-wrapper {
            overflow-x: auto;
            width: calc(100% - 105px);
            .bikes-slider {
                display: flex;
                flex-wrap: nowrap;
                .bike-box {
                    min-width: 120px;
                    figure {
                        padding: 10px 10px 3px 10px;
                        img {
                            height: 44px;
                        }
                    }
                    .bike-title {
                        height: 26px;
                        h4 {
                            font-size: 10px;
                            line-height: 12px;
                            padding: 0 8px;
                        }
                    }
                }
            }
        }
    }
}
