//Form holder
.form-holder {
    padding: 30px 0;
    text-align: center;
    @include media(">=desktop") {
        padding: 43px 0 23px;
    }
    .container {
        @include media(">=desktop") {
            max-width: 1040px;
        }

        > .h3 {
            text-transform: uppercase;
            @include media(">=desktop") {
                max-width: 700px;
                margin: 0 auto 62px;
            }
        }
    }
    .h1 {
        max-width: 650px;
        margin: 0 auto 40px;
        @include media(">=desktop") {
            margin-bottom: 45px;
        }
    }
    header {
        background-color: $black;
        padding: 20px;
        @include media(">=tablet-landscape") {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 15px 80px;
        }
        @include media(">=desktop") {
            padding: 15px 130px;
        }

        .h4,
        h4 {
            color: $white;
            @include media(">=tablet-landscape") {
                margin-bottom: 0;
            }
        }
    }

    .tel {
        display: inline-block;
        position: relative;
        z-index: 10;
        padding-left: 40px;
        color: $white;
        margin-bottom: 0;
        @include media(">=desktop") {
            transition: color 0.35s;
            &:hover {
                //color: $white;

                svg {
                    animation: ring 3s 0.7s ease-in-out infinite;
                }
            }
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: -2px;
            width: 29px;
            height: 29px;
            border-radius: 50%;
            background-color: $white;
            z-index: -1;
            @include media(">=desktop") {
                top: 0;
            }
        }

        svg {
            width: 13px;
            height: 15px;
            position: absolute;
            top: 5px;
            left: 8px;
            z-index: 2;
            @include media(">=desktop") {
                top: 7px;
            }
        }
    }

    form {
        padding: 30px 20px;
        border: solid #e2e2e5;
        border-width: 0 1px 1px 1px;
        @include media(">=tablet-landscape") {
            padding: 37px 80px;
        }
        @include media(">=desktop") {
            padding: 37px 130px 39px;
        }
    }

    .note {
        text-align: right;
        font-size: 12px;
        margin-bottom: 22px;
    }

    .form-group {
        text-align: left;
        @include media(">=tablet-landscape") {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;
        }
        @include media(">=desktop") {
            padding-bottom: 10px;
        }
        span {
            display: block;
            width: 100%;
        }
    }

    .wrap {
        @include media(">=tablet-landscape") {
            width: calc(50% - 15px);
        }
    }

    label {
        font: 700 14px/20px $main-ff;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 5px;
        @include media(">=tablet-landscape") {
            margin: 1px 0 15px;
        }
    }

    .captcha {
        text-align: left;
        margin-bottom: 20px;
        @include media(">=desktop") {
            margin-bottom: 29px;
        }
        img {
            vertical-align: top;
        }
    }

    .add-info {
        font: 500 12px/26px $main-ff;
        margin-bottom: 20px;
        @include media(">=desktop") {
            margin-bottom: 32px;
        }
    }

    .button {
        margin-bottom: 0;
    }
    .datepicker {
        &:focus {
            border-color: transparent;
        }
    }
    .datepicker-holder {
        position: relative;
        input {
            padding-left: 50px;
            cursor: pointer;
        }
    }
    .ico-calendar {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: -1;
        img {
            width: 20px;
            height: 18px;
        }
    }
}

.wpcf7 {
    .wpcf7-response-output {
        font-size: 14px;
        color: darkgray;
        text-align: center;
        margin-bottom: 30px;
    }
    .wpcf7-not-valid-tip {
        font-size: 13px;
        display: block;
        margin-bottom: 14px;
        margin-top: -5px;
    }
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    background: $green;
    color: $white;
}

.ui-state-active {
    background: $green !important;
    color: $white !important;
}
