.cta-box {
    background-color: #000;
    display: inline-flex;
    width: 100%;
    padding: 30px;
    align-items: center;
    justify-content: center;
    height: 120px;
    img {
        margin-right: 24px;
    }
    span {
        font-size: 26px;
        color: #fff;
        font-weight: 500;
    }
    @include media("<=mobile-landscape") {
        height: auto;
    }
}
