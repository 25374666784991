.main-slider-wrapper {
    position: relative;
    margin-bottom: 12px;
    .main-slider {
        position: relative;
        max-height: 400px;
        overflow: hidden;
        .slide {
            height: 400px;
            position: relative;
            background-size: cover;
            background-position: center center;
            .text-holder {
                height: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                padding-left: 130px;
                max-width: 50%;
                color: #fff;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                h3 {
                    text-transform: uppercase;
                    font-size: 36px;
                    line-height: normal;
                    font-weight: bold;
                    letter-spacing: -0.9px;
                    margin-bottom: 30px;
                }
                p {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: -0.45px;
                    margin-bottom: 22px;
                }
                button {
                    border: 3px solid #fff;
                    padding: 9px 16px;
                    color: #fff;
                    font-weight: bold;
                    line-height: normal;
                    margin: 0;
                    height: auto;
                    background-color: transparent;
                    text-transform: none;
                    font-size: 18px;
                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
            img.mobile {
                display: none;
            }
            img.desktop {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.overlay-active {
                &:before {
                    content: " ";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 35px;
                    right: 20%;
                    background: rgb(38, 196, 56);
                    background: linear-gradient(90deg, rgba(38, 196, 56, 1) 0%, rgba(38, 196, 56, 0) 100%);
                    z-index: 1;
                }
            }
        }
    }
    .arrow {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #000;
        z-index: 9;
        top: 50%;
        transform: translateY(-50%);
        &.prev {
            left: 20px;
            background: url("#{$images-path}chevron-thin-left.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            transform: translateY(-50%);
        }
        &.next {
            right: 20px;
            background: url("#{$images-path}chevron-thin-right.svg") #000 50% 50% no-repeat;
            background-size: 30%;
            transform: translateY(-50%);
        }
        &:hover {
            background-color: #26c438;
        }
    }
    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        li {
            margin: 0 15px;
            height: 11px;
            &.slick-active {
                button {
                    background-color: $green;
                }
            }
        }
        button {
            width: 11px;
            height: 11px;
            background-color: #fff;
        }
    }
    @include media("<=tablet") {
        margin: 0 -10px;
        .arrow {
            display: none;
        }
        .main-slider {
            .slide {
                // height: 375px;
                .text-holder {
                    max-width: 70%;
                    padding-left: 10px;
                    justify-content: flex-end;
                    padding-bottom: 25px;
                    h3 {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                    p {
                        display: none;
                    }
                    button {
                        border-width: 3px;
                        padding: 8px;
                        font-size: 14px;
                    }
                }
                img {
                    &.mobile {
                        display: block;
                        width: 100%;
                        height: 365px;
                        object-fit: contain;
                    }
                    &.desktop {
                        display: none;
                    }
                }
                &.overlay-active:before {
                    left: 0;
                    top: 30%;
                    bottom: 0;
                    right: 0;
                    background: rgb(38, 196, 56);
                    background: linear-gradient(180deg, rgba(38, 196, 56, 0) 0%, rgba(38, 196, 56, 1) 100%);
                    z-index: 1;
                }
            }
        }
        .slick-dots {
            display: block !important;
            bottom: 0;
            li {
                margin: 0 10px;
                button {
                    width: 12px;
                    height: 12px;
                    border: 1px solid $gray;
                }
                &.slick-active {
                    button {
                        background-color: #000 !important;
                        border: 1px solid #000;
                    }
                }
            }
        }
    }
}

.tours-slider-wrapper,
.rentals-slider-wrapper {
    .tours-slider,
    .rentals-slider {
        .slick-dots {
            margin: 0;
            li {
                margin: 0 5px;
                button {
                    width: 12px;
                    height: 12px;
                    border: 1px solid $gray;
                }
                &.slick-active {
                    button {
                        background-color: #000 !important;
                        border: 1px solid #000;
                    }
                }
            }
        }
    }
}
