.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1052;
  background: #fff;

  @include media(">=desktop") {
    position: absolute;
    height: 96px;
  }

  &.home {
    @include media(">=desktop") {
      background-color: transparent;
      transition: background-color 0.3s;
      &:hover {
        background: $black;
      }
    }
  }

  &.sticky {
    background: $black;
  }

  > .container {
    @include media(">=desktop") {
      position: initial;
    }
  }

  .header-top-menu {
    position: absolute;
    right: 15px;
    top: 10px;
    display: none;

    @include media(">=tablet") {
      display: block;
    }

    @include media(">=desktop") {
      position: initial;
    }

    ul {
      display: flex;
      justify-content: flex-end;
      margin: 8px 0 0;
      li {
        margin-left: 20px;
      }
      a {
        color: #515154;
        font-size: 12px;
        line-height: 12px;
        &:hover {
          color: $green;
        }
      }
    }
  }

  //header-left
  .header-left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 0;
    height: 70px;

    @include media(">=tablet") {
      justify-content: flex-start;
      height: auto;
    }

    @include media(">=desktop") {
      padding: 0;
    }
  }

  .header-right {
    background-color: #F3F3F3;
    margin: 0 -15px;
    position: relative;
    display: none;
    box-shadow: 1px 10px 3px 0 rgba(0,0,0,0.06) inset;

    @include media(">=tablet") {
      display: block;
      box-shadow: none;

      &:before {
        content: "";
        position: absolute;
        left: -100%;
        right: -100%;
        top: 0;
        bottom: 0;
        background-color: #F3F3F3;
      }
    }

    @include media(">=desktop") {
      background-color: #fff;
      margin: 0;
      position: initial;
      &:before {
        display: none;
      }
    }
  }

  .logo {
    display: inline-flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include media(">=tablet") {
      position: initial;
      transform: none;
    }

    img {
      max-width: 262px;

      @include media(">=desktop") {
        max-width: 306px;
      }
    }

    @media(min-width: 667px) {
      .mobile {
        display:none
      }
    }
    
    @media(max-width: 666px) {
      .desc {
        display:none
      }
    }
  }

  .header-container {
    display: flex;
    flex-direction: column;

    @include media(">=desktop") {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

//btn-menu
.btn-menu {
  width: 60px;
  height: 70px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 15;
  margin: 0;
  background-color: #fff;

  span {
    position: absolute;
    width: 20px;
    height: 3px;
    left: 50%;
    top: 50%;
    margin: -9px 0 0 -10px;
    transition-timing-function: ease;
    transition-duration: 0.4s;
    transition-property: transform, background-color;
    background-color: #000;
  }

  span::before,
  span::after {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.4s;
    transition-property: transform, background-color;
    border-radius: 4px;
    background-color: #000;
  }

  span::before {
    top: 8px;
    transition-timing-function: ease;
    transition-duration: 0.4s;
    transition-property: transform, opacity, background-color;
  }

  span::after {
    top: 16px;
  }

  &.opened {

    span {
      height: 2px;
      transform: translate3d(0, 10px, 0) rotate(45deg);
      background-color: $green;
      border-radius: 4px;

      &::before {
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        opacity: 0;
        background-color: $green;
      }

      &::after {
        top: 19px;
        width: 21px;
        height: 2px;
        transform: translate3d(-1px, -19px, 0px) rotate(-90deg);
        background-color: $green;
      }
    }
  }

  @include media(">=tablet") {
    display: none;
  }
}

//btn-back
.btn-back {
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: $white;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
