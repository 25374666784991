.footer {
    background-color: $black;
    position: relative;
    z-index: 99;

    h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $green;
    }

    .footer-top {
        padding: 60px 0 35px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .footer-top-wrapper {
            display: flex;
            align-items: flex-start;
            .logo-holder {
                margin-right: 90px;
                img {
                    min-width: 122px;
                }
            }
            .content-blocks {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .content-holder {
                    color: #fff;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    max-width: 220px;
                    p {
                        margin-bottom: 10px;
                    }
                    a {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .footer-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-menu {
            width: 65%;
            display: flex;
            align-items: flex-start;
            margin: 76px 0;

            .menu-block {
                margin-right: 55px;
            }

            h6 {
                color: $green;
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                margin-bottom: 20px;
                text-transform: none;
                white-space: nowrap;
            }

            ul {
                li {
                    a {
                        color: #fff;
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 500;
                        margin-bottom: 12px;
                        display: block;
                    }
                }
            }
        }

        .footer-right {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            height: 100%;
            width: 35%;
            margin: 70px 0;
            ul.social {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                li {
                    a {
                        margin: 0 35px;
                        svg {
                            height: 26px;
                            width: auto;
                        }
                    }
                }
            }
            .phones-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 70px;
                .icon {
                    margin-right: 30px;
                    margin-left: 30px;
                }
                .phones {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 28px;
                    span {
                        color: $green;
                    }
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    .cancellation-warning {
        background: url("#{$images-path}title-divider.svg") top left no-repeat;
        background-size: 100%;
        color: #fff;
        padding-top: 70px;
        .slogan {
            display: flex;
            align-items: center;
            justify-content: center;
            .bordered {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                border-top: 1px solid #ffffff;
                border-bottom: 1px solid #ffffff;
                padding: 3px 0;
            }
            .hours {
                font-weight: 500;
                font-size: 34px;
                line-height: 41px;
                margin: 0 9px;
            }
        }
        .warning {
            margin-top: 5px;
            opacity: 0.5;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
        }
    }

    .footer-bottom {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        margin-top: 5px;
        padding: 30px 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        .footer-bottom-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            ul {
                display: flex;
                align-items: center;
                margin: 0;
                li {
                    margin-left: 30px;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .activate-zendesk {
        border-radius: 30px;
        background-color: $green;
        padding: 12px 18px;
        color: #fff;
        position: fixed;
        bottom: 20px;
        right: 20px;
        border: none;
        font-weight: bold;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        i {
            margin-right: 8px;
        }
    }

    @include media("<=tablet") {
        .footer-top {
            padding: 20px 0 12px;
            border-bottom: none;
            .footer-top-wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .logo-holder {
                    margin-right: 0;
                    margin-bottom: 0;
                    img {
                        width: 82px;
                        min-width: initial;
                    }
                }
                .content-blocks {
                    display: none;
                }
            }
        }
        .footer-wrapper {
            flex-direction: column;
            .footer-menu {
                width: 100%;
                padding: 0;
                justify-content: space-between;
                margin: 0;
                flex-wrap: wrap;
                .menu-block {
                    flex-basis: 50%;
                    max-width: 50%;
                    margin-right: 0;
                }
                h6 {
                    font-size: 14px;
                    margin-bottom: 8px;
                }
                ul {
                    margin-bottom: 20px;
                    li a {
                        margin-bottom: 8px;
                        font-size: 12px;
                        line-height: 14.4px;
                    }
                }
            }
            .footer-right {
                padding: 0;
                width: 100%;
                border-left: none;
                display: flex;
                flex-direction: column-reverse;
                margin-top: 0;
                margin-bottom: 0;
                .phones-wrapper {
                    margin: 0 -10px;
                    padding: 20px 0;
                    border-top: 1px solid rgba(255, 255, 255, 0.1);
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    .icon {
                        margin-left: 0;
                    }
                }
                ul.social {
                    margin-bottom: 0;
                    padding: 20px 0;
                    justify-content: center;
                    li {
                        display: inline-flex;
                        a {
                            display: inline-flex;
                            margin: 0 25px;
                        }
                    }
                }
            }
        }
        .cancellation-warning {
            background: url("#{$images-path}title-divider-mobile.svg") top left no-repeat;
            background-size: 100%;
            margin-top: -20px;
            .slogan {
                flex-direction: column;
            }
            .warning {
                text-align: center;
                margin-bottom: 14px;
            }
        }
        .footer-bottom {
            padding: 12px 0 0;
            .container {
                padding: 0;
            }
            .footer-bottom-wrapper {
                flex-direction: column-reverse;
                ul {
                    width: 100%;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    padding-bottom: 12px;
                    margin: 0 -9px;
                    justify-content: center;
                    li {
                        margin: 0 20px;
                    }
                }
                .copyright {
                    padding: 12px 0;
                }
            }
        }
    }
    @include media("<=mobile-landscape") {
        .activate-zendesk {
            display: none;
        }
    }
}

@media (max-width: 500px) {
    .grecaptcha-badge,
    #_hj_feedback_container {
        display: none;
    }
}
