.group-detail-page {
    .page-title-bar {
        @include page-title-bar;
        padding-bottom: 15px;

        .description {
            margin-right: 92px;
        }
    }

    .section-title {
        @include section-title;
    }

    .group-slider-info-title {
        font-weight: bold;
        font-size: 50px;
        line-height: 60px;
        text-align: left;
        margin-bottom: 20px;
        text-transform: none;
    }

    .faq .group-slider-info-title {
        margin-bottom: 60px;
    }

    .faq .faq-holder .question h4 {
        font-size: 16px;
        line-height: 22px;
    }

    .faq .faq-holder .question svg path {
        stroke: #BFBFC4;
    }

    section.call-to-action {
        padding-top: 30px;
        margin-bottom: 55px;

        h2 {
            margin-bottom: 60px;
            text-transform: none;
            text-align: center;
        }

        .quote-form {
            max-width: 970px;
            margin: 0 auto;

            br {
                display: none;
            }

            p {
                margin-bottom: 0;
            }

            label {
                margin-bottom: 15px;
                font-weight: 500;
            }

            .wrap {
                margin-bottom: 10px;
            }

            .experience-selector {
                select {
                    display: none;

                    &.active {
                        display: block;
                    }
                }
            }

            .submit-holder {
                text-align: center;
                margin-bottom: 60px;
            }
        }
    }

    .city-boxes-wrapper {
        margin: 60px 0;

        @include media("<=tablet-landscape") {
            margin: 15px 0;
        }
    }

    section.group-ideas-section {
        background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%), #ffffff;
        margin-bottom: 80px;
        padding: 30px 0;
    }

    section.gallery-section {
        background-color: $black;
        padding: 60px 0;
        margin-bottom: 60px;

        @include media("<=tablet-landscape") {
            padding: 30px 0;
            margin-bottom: 30px;
        }
    }

    .info-boxes-wrapper.other {
        border-bottom: 1px solid #e9e9e9;
        padding: 60px 0 30px;

        .section-title {
            margin-bottom: 30px;
        }

        .col-6 {
            padding: 0;
        }

        .info-box {
            margin-bottom: 30px;
            height: 100%;

            figure {
                height: 90px;
                margin-bottom: 30px;
            }

            h4 {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 10px;
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                margin: 0;
                opacity: 1;
            }
        }
    }

    .single-info-holder {
        @include single-info-holder;
    }

    section.top-tour {
        margin-bottom: 20px;

        .tour-box {
            display: flex;
            position: relative;

            figure {
                width: 170px;
                min-width: 170px;
                height: 170px;
            }

            .infos-holder {
                padding: 30px;

                h4 {
                    margin-bottom: 10px;
                    -webkit-line-clamp: 3;
                }

                .duration {
                    opacity: 0.5;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            .details-link {
                position: absolute;
                right: 20px;
                bottom: 15px;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .group-gallery {
        .gallery-slider {
            margin: 0 -15px;

            .slide {
                a {
                    display: flex;
                    padding: 0 15px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .slick-arrow {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                z-index: 9;

                &.slick-next {
                    background: url("#{$images-path}chevron-right.svg") #fff 50% 50% no-repeat;
                    right: 15px;
                }

                &.slick-prev {
                    background: url("#{$images-path}chevron-left.svg") #fff 50% 50% no-repeat;
                    left: 15px;
                }
            }
        }
    }

    .bottom-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        z-index: 99;
        padding: 22px 0;
        color: #fff;
        font-weight: 500;

        .holder {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                color: #fff;
                display: inline-flex;
                align-items: center;

                i {
                    margin-right: 8px;
                }
            }
        }
    }

    @include media("<=tablet-landscape") {
        section.top-tour {
            .tour-box {
                .infos-holder {
                    padding: 10px;
                }
            }
        }
    }

    @include media("<=tablet-landscape") {
        section.top-tour {
            .tour-box {
                figure {
                    width: 110px;
                    min-width: 110px;
                    overflow: hidden;

                    img {
                        height: 170px;
                        width: 170px;
                        max-width: initial;
                    }
                }
            }
        }
    }

    @include media("<=mobile-landscape") {
        .main-slider-wrapper {
            margin-bottom: 30px;
        }

        section.call-to-action {
            .grid {
                @include mobile-full-grid;
            }

            .cta-box {
                padding: 10px;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;

                img {
                    margin-right: 14px;
                    height: 30px;
                    width: auto;
                }

                span {
                    font-size: 14px;
                }
            }
        }

        .single-info-holder {
            @include single-info-holder-mobile;
        }

        section.top-tour {
            .grid {
                @include mobile-full-grid;

                .tour-box {
                    figure {
                        width: 120px;
                        min-width: 120px;
                        height: auto;

                        img {
                            height: 120px;
                            width: 120px;
                        }
                    }

                    .infos-holder {
                        h4 {
                            font-size: 14px;
                            min-height: auto;
                        }
                    }

                    .details-link {
                        bottom: 8px;
                        right: 8px;
                    }
                }
            }
        }

        section.group-gallery {
            padding: 0 0 15px;
            border-bottom: 1px solid #e9e9e9;
            margin-bottom: 15px;

            .slick-dots {
                margin-top: 0;

                li {
                    button {
                        border: 1px solid #c4c4c4;
                    }

                    &.slick-active {
                        button {
                            border-color: $black;
                        }
                    }
                }
            }
        }

        .bottom-bar {
            padding: 10px 0;

            .holder {
                flex-direction: column;

                .title {
                    display: none;
                }

                a {
                    &:nth-of-type(1) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

body.page-template-page-groups {
    .fancybox-container {
        .fancybox-bg {
            opacity: 0.2;
        }
    }

    .fancybox-slide--html {
        padding: 0;
    }

    .blog-posts {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}
