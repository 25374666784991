//Event
.event-listing-page {
    .page-title-bar {
        @include page-title-bar;
        padding-bottom: 15px;
        margin-bottom: 15px;
        h1 {
            margin-bottom: 4px;
            text-transform: uppercase;
        }
    }
    .events-description {
        margin-bottom: 30px;
        @include media("<=tablet") {
            display: none;
        }
    }
    margin-bottom: 30px;
    @include media("<=tablet") {
        margin-bottom: 0;
    }
    .open-filter-box-button {
        display: none;
        width: 100%;
        background-color: #fff;
        margin-bottom: 15px;
        padding: 15px;
        font-size: 14px;
        color: #000;
        font-weight: bold;
        border: 1px solid #bfbfc4;
        i {
            background: url(../images/filter-dark.svg) center center no-repeat;
            background-size: contain;
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }
    .sorts {
        .sort-by {
            font-weight: bold;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 18px;
            & a {
                font-size: 16px;
                margin-left: 15px;
            }
        }
    }
    .events-list {
        padding-bottom: 25px;
        .col-8 {
            margin-bottom: 30px;
        }
        .rental-box {
            height: 100%;
            .box-img-holder {
                position: relative;
                display: flex;
                flex-direction: column;
                img.organizer-logo {
                    position: absolute;
                    bottom: -15px;
                    left: 15px;
                    width: 60px !important;
                    height: 60px !important;
                    object-fit: cover;
                    filter: drop-shadow(0px 9px 18px rgba(0, 0, 0, 0.03))
                        drop-shadow(0px 1.12694px 2.25388px rgba(0, 0, 0, 0.015));
                    background: white;
                    border: 1px solid #e5e5e5;
                }
            }
            .infos-holder {
                height: unset;
                h4 {
                    font-size: 19px;
                    margin-top: 24px;
                    margin-bottom: 24px;
                }
                div {
                    font-size: 12px;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    @include media("<=tablet") {
                        font-size: 10px;
                    }
                }
                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 16px;
                    flex-shrink: 0;
                }
                .short-description {
                    font-size: 14px;
                    font-weight: normal;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    p {
                        margin: 0;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 1.5;
                    }
                }
            }
            &:hover {
                border-color: $green;
                .infos-holder {
                    h4 {
                        color: $green !important;
                    }
                    svg {
                        path {
                            stroke: $green;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 667px) {
        .event-main-title {
            .bg .container-holder .main-content {
                height: unset;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                color: #fff;
            }
        }
        .daterangepicker .drp-selected {
            display: inline-block;
            font-size: 9px;
            padding-right: 8px;
        }
        .open-filter-box-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        [class*="col-"],
        [class*="col_"],
        [class~="col"] {
            flex-basis: 100%;
            max-width: 100%;
        }
        .breadcrumbs {
            white-space: nowrap;
            overflow-x: auto;
            margin-bottom: 10px;
            padding: 9px;
            font-size: 12px;
            a {
                font-size: 12px;
            }
            .arrow {
                height: 9px;
                margin: 0 9px;
            }
        }
        .rental-box {
            img.organizer-logo {
                position: absolute;
                bottom: -10px;
                left: 10px;
                width: 40px !important;
                height: 40px !important;
            }
            .infos-holder h4 {
                padding: 0;
                font-size: 12px !important;
                line-height: 14px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: auto;
                margin-bottom: 8px;
                min-height: 42px;
            }
        }
        .grid.events-list {
            margin: 0 -4.5px;
            [class*="col-"],
            [class*="col_"],
            [class~="col"] {
                flex-basis: 50%;
                max-width: 50%;
                padding: 0 4.5px;
            }
        }
        .single-info-holder {
            @include single-info-holder-mobile;
        }
        .description-mobile {
            display: block;
            background-color: #efefef;
            margin: 0 -15px;
            padding: 15px;
        }
    }
}

.single-events {
    @include media("<=tablet") {
        .grid {
            @include mobile-full-grid;
            &.reg-holder {
                padding: 0;
                overflow: auto;
                margin: 0 -10px 0 0;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                .col-8 {
                    padding: 0 5px;
                    width: 270px;
                    -ms-flex-preferred-size: auto;
                    flex-basis: auto;
                    max-width: inherit;
                    .value {
                        font-size: 50px;
                    }
                    .reg-button,
                    .sold-out-text {
                        max-width: 200px;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
    .bike-carousel {
        .slide {
            img {
                max-width: 100%;
            }
        }
        @include media("<=tablet") {
            .arrow {
                display: none;
            }
        }
        .slick-dots li button {
            border: 1px solid #bfbfc4 !important;
            width: 12px;
            height: 12px;
        }
        .slick-dots li.slick-active button {
            border: 1px solid #000 !important;
            background: #000 !important;
        }
    }
    .event-main-title {
        background-color: #000;
        .bg {
            min-height: 480px;
            background-position: top center;
            background-position: top center;
            background-size: 100% auto;
            background-color: black;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            img {
                width: 100%;
                object-fit: cover;
                max-height: 600px;
            }
            .container-holder {
                flex: 1;
                display: flex;
                align-items: flex-end;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5%, #000000 75%), rgba(0, 0, 0, 0.3);
                @include media("<=tablet") {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 25%), rgba(0, 0, 0, 0.3);
                }
                .main-content {
                    color: #fff;
                    h1 {
                        font-size: 40px;
                        line-height: 50px;
                        text-transform: none;
                        margin-bottom: 3rem;
                        font-weight: 400 !important;
                        text-align: center;
                        margin-top: 40px;
                    }
                    .item-holder {
                        justify-content: center;
                    }
                    .icon-item {
                        margin-bottom: 3rem;
                        min-width: 275px;
                        figure {
                            height: 64px;
                            img {
                                max-width: 100%;
                                height: 64px;
                                object-fit: contain;
                                object-position: left center;
                            }
                            margin-bottom: 1rem;
                        }
                        .item-title {
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 32px;
                            height: 64px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        .item-desc {
                            font-size: 16px;
                            line-height: 20px;
                        }
                        @include media("<=tablet") {
                            text-align: center;
                            figure img {
                                object-position: center;
                            }
                        }
                    }
                }
            }
        }
    }

    section {
        padding-top: 60px;
        padding-bottom: 60px;
        h2 {
            @include section-title;
        }
        .flex-align-center {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }
        h2.green {
            color: $green;
        }
    }

    section.content {
        padding-top: 0;
        .content-holder {
            .excerpt p:last-child {
                margin-bottom: 0;
            }
            .more {
                display: none;
            }
            &.passive {
                .more {
                    display: block;
                }
            }
        }
        .show-more-button {
            display: block;
            text-decoration: underline;
        }
    }

    section.event-info {
        .flex {
            display: flex;
            .icon {
                flex: none;
                margin-right: 15px;
                width: 30px;
                img {
                    width: 30px;
                }
            }
            .content {
                flex: 1;
                width: 100%;
                .toptitle {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 24px;
                    color: #848487;
                    margin-bottom: 15px;
                }
                .organizer-link {
                    color: #000;
                    .organizer-logo {
                        max-height: 210px;
                        padding: 20px;
                        width: 100%;
                        object-fit: contain;
                    }
                }                
                .title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                }
                .small {
                    font-size: 12px;
                    line-height: 22px;
                }
            }
            @include media("<=tablet") {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                .icon {
                    margin: 0;
                }
            }
        }
        .map-iframe {
            position: relative;
            margin-bottom: 15px;
            max-width: 100%;
        }
        .direction {
            color: #848487;
            text-decoration: none;
            outline: none;
            font-size: 0.75em;
            border-bottom: 1px solid #efefef;
            padding-bottom: 8px;
        }
        .image {
            img {
                max-width: 100%;
                border: 1px solid #e9e9e9;
            }
            margin-bottom: 15px;
        }
    }

    section.registration {
        background: #fafafa;
        .reg-box {
            position: relative;
            @include media("<tablet") {
                margin-bottom: 1rem;
            }
            &.sold-out {
                opacity: 0.5;
                pointer-events: none;
            }
            .disabled {
                pointer-events: none;
            }
            .ticket-bg {
                padding-bottom: 79.1%;
                width: 100%;
                max-width: 100%;
                margin: 0 auto;
                display: block;
                background: transparent url("#{$images-path}ticket-register.svg") top center no-repeat;
                background-size: contain;
                filter: grayscale(100%);
                opacity: 0.2;
            }
            .sold-out-text {
                font-weight: bold;
                font-size: 16px;
                line-height: 44px;
                margin-bottom: 2rem;
                margin-top: auto;
            }
            &:hover {
                .ticket-bg {
                    filter: none;
                    opacity: 1;
                }
                .reg-button {
                    background-color: #26c438;
                    color: #fff;
                }
            }
            .reg-info {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                .type {
                    font-size: 24px;
                    line-height: 2;
                    margin-top: 1rem;
                }
                .value {
                    font-size: 80px;
                    font-weight: 500;
                    line-height: 1.25;
                    span {
                        font-size: 40px;
                    }
                }
                .btn {
                    border: 2px solid #26c438;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1;
                    letter-spacing: -0.45px;
                    text-align: center;
                    padding: 6px 12px 7px;
                    display: block;
                    -webkit-transition: all ease 0.3s;
                    transition: all ease 0.3s;
                    width: 100%;
                    padding: 12px 8px;
                    font-size: 16px;
                }
                .reg-button {
                    margin-top: auto;
                    margin-bottom: 2rem;
                    max-width: 250px;
                }
                @include media("<tablet") {
                    .value {
                        font-size: 60px;
                    }
                    .reg-button {
                        margin-bottom: 1rem;
                        max-width: 200px;
                    }
                }
            }
        }
    }

    section.sections {
        @include media(">=tablet") {
            .flex-order-1 {
                order: 1;
            }
            .flex-order-2 {
                order: 2;
            }
        }

        .button-holder {
            margin-top: 2rem;
            text-align: left;
            @include media("<tablet") {
                text-align: center;
            }
        }

        .desc-holder .grid {
            .col-12 {
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        .mobile-title {
            display: none;
        }
        .desktop-title {
            display: block;
        }
        @include media("<tablet") {
            .mobile-title {
                display: block;
            }
            .desktop-title {
                display: none;
            }
            img {
                width: 100%;
                max-height: 290px;
                object-fit: cover;
            }
        }
    }

    section.bundles {
        .bundle-box {
            background-color: #fafafa;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            .bundle-body {
                flex: 1;
                padding: 20px 15px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                @include media("<tablet") {
                    flex-wrap: wrap;
                }
                .ticket {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    @include media("<tablet") {
                        width: 125px;
                    }
                    .small {
                        font-size: 16px;
                    }
                    .price {
                        font-weight: 500;
                        font-size: 40px;
                        line-height: 80px;
                        span {
                            font-size: 20px;
                        }
                    }
                }
                .plus {
                    margin: 15px;
                }
                .bike,
                .bundle {
                    min-width: 125px;
                    min-height: 120px;
                    max-width: 200px;
                    padding: 5px;
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;
                    @include media("<tablet") {
                        width: 125px;
                    }
                    .small {
                        font-size: 16px;
                    }
                    img {
                        object-fit: contain;
                    }
                }
            }
            .bundle-footer {
                background-color: #f3f3f3;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                .small {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                }
                .price {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 24px;
                }
                .btn {
                    padding: 14px 54px;
                    background-color: transparent;
                    color: #26c438;
                    border: 2px solid #26c438;
                }
            }
        }
        .bundle-box:hover {
            .bundle-body {
                background-color: #ddfcd3;
            }
            .bundle-footer {
                background-color: #26c43829;
            }
            .btn {
                background-color: #26c438;
                border-color: #26c438;
                color: #fff;
            }
        }
        .ticket {
            background: transparent url("#{$images-path}ticket-bundle.svg") center no-repeat;
            background-size: contain;
            width: 180px;
            height: 180px;
            min-width: 140px;
        }
        .plus {
            background: transparent url("#{$images-path}plus-green.svg") center no-repeat;
            background-size: contain;
            width: 30px;
            height: 31px;
            display: inline-block;
        }
        @include media("<tablet") {
            .col-12 {
                margin-bottom: 15px;
            }
        }
    }

    section.events {
        h2 {
            @include section-title;
        }

        &.popular-events {
            background-color: #000000;
            color: #fff;
            .event-box {
                border: 1px solid #333;
                .date {
                    color: #bfbfc4;
                    font-weight: normal;
                }
            }
            .bike-carousel .arrow.next {
                background: url(../images/chevron-thin-right-green.svg) #fff 50% 50% no-repeat;
            }
            .bike-carousel .arrow.prev {
                background: url(../images/chevron-thin-left-green.svg) #fff 50% 50% no-repeat;
            }
        }

        &.nearby-events {
            background-color: #fff;
            color: #000;
            h2 {
                color: $green;
            }
            .event-box {
                border: 1px solid #e9e9e9;
                .infos-holder h4 {
                    color: #000;
                }
                .date {
                    color: #848487;
                    font-weight: normal;
                }
            }
        }

        .event-box {
            margin-bottom: 0;
            border: 1px solid #e9e9e9;
        }
        @include media("<tablet") {
            .grid {
                padding: 0;
                overflow: auto;
                margin: 0 -10px 0 0;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                .col-6 {
                    padding: 0 5px;
                    width: 270px;
                    -ms-flex-preferred-size: auto;
                    flex-basis: auto;
                    max-width: inherit;
                }
            }
        }
    }
}

.event {
    background-color: $black;
    padding: 25px 20px;
    position: relative;
    margin-bottom: 35px;
    z-index: 10;
    color: $white;
    @include media(">=tablet") {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 50px;
        padding: 40px;
    }
    @include media(">=desktop") {
        max-width: 100%;
        padding: 30px 60px 0;
        margin-bottom: 124px;
    }

    .decor {
        position: absolute;
        right: -15px;
        top: 30px;
        width: 57px;
        height: 60px;
        z-index: -1;
        @include media(">=desktop") {
            top: 100px;
            right: auto;
            left: -28px;
            z-index: 1;
        }
    }

    .decor-gray {
        display: none;
        @include media(">=desktop") {
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 57px;
            height: 60px;
            z-index: 1;
        }
    }

    header {
        @include media(">=desktop") {
            overflow: hidden;
            margin: 0 -30px 20px 0;
        }
    }

    .logo {
        max-width: 200px;
        max-height: 100px;
        vertical-align: top;
        @include media(">=desktop") {
            max-width: 30%;
            float: right;
        }
    }

    h2 {
        color: $white;
        padding-top: 20px;
        text-align: left;
        @include media(">=desktop") {
            margin-bottom: 0;
            padding-top: 0;
            overflow: hidden;
            max-width: 550px;
            min-height: 56px;
            margin-top: 20px;
        }
        @include media(">=large-desktop") {
            min-height: 60px;
        }

        a {
            color: $white;
        }
    }

    .image {
        margin: 0 -20px 20px;
        height: 250px;
        position: relative;
        background: {
            size: cover !important;
            position: 50% 50% !important;
        }
        @include media(">=tablet") {
            margin: 0 -40px 30px;
        }
        @include media(">=desktop") {
            width: 500px;
            height: 380px;
            float: left;
            margin: 0 60px 60px -60px;
        }
        @include media(">=large-desktop") {
            width: 620px;
            height: 380px;
        }

        a {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .info {
        @include media(">=desktop") {
            &:after {
                content: "";
                clear: both;
                display: block;
            }
        }

        p {
            @include media(">=desktop") {
                margin-bottom: 30px;
            }
        }
    }

    .wrap {
        overflow: hidden;
        padding-bottom: 30px;
    }

    ul {
        @include media(">=tablet") {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 0 5px;
        }
    }

    li {
        position: relative;
        color: $gray;
        padding-left: 40px;
        margin-bottom: 10px;
        @include media(">=tablet") {
            width: calc(50% - 16px);
            margin-bottom: 28px;
        }
    }

    .ico {
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        text-align: center;

        svg {
            width: auto;
            height: 100%;
            max-height: 24px;
        }
    }

    address {
        font-style: normal;
    }
}

//Event description
.event-description {
    background-color: $black;
    padding: 30px 0;
    color: #bfbfc4;
    text-align: center;
    @include media(">=desktop") {
        padding: 76px 0 50px;
        //margin: -50px 0 50px;
    }
    @include media(">=large-desktop") {
        //margin: -75px 0 100px;
        margin-bottom: 40px;
    }
    h2,
    .h2 {
        color: $white;
        margin-bottom: 40px;
        text-align: left;
        @include media(">=desktop") {
            margin-bottom: 54px;
        }
    }

    ul {
        @include media(">=desktop") {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;
        }
        @include media(">=large-desktop") {
            max-width: 1400px;
            width: 100%;
            margin: 0 auto;
        }
    }

    li {
        background-color: $medium-black;
        padding: 30px 20px;
        max-width: 360px;
        width: 100%;
        margin: 0 auto 30px;
        @include media(">=desktop") {
            padding: 50px 30px 40px;
            width: 30%;
            margin: 0 0 30px;
            &:nth-child(3n-1) {
                margin-bottom: 130px;
            }
        }
        @include media(">=large-desktop") {
            width: 100%;
            &:nth-child(3n-1) {
                margin-bottom: 164px;
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    h3,
    .h3 {
        margin: 25px 0;
        color: $white;
        text-transform: uppercase;
        @include media(">=desktop") {
            margin: 41px 0 20px;
        }
        strong {
            color: $green;
        }
    }

    .button {
        @include btn-outline-green;
        display: initial;
        @include media(">=desktop") {
            margin: 0 0 -11px;
            transform: translateY(-120px);
        }
        @include media(">=large-desktop") {
            margin: 0 0 -11px;
            transform: translateY(-90px);
        }
    }
}
