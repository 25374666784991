.blog-post-box {
    position: relative;
    transition: $base-transition;

    figure {
        display: flex;
        img {
            width: 100%;
            height: auto;
        }
    }

    h4 {
    }

    .post-details {
        border: 1px solid #e9e9e9;
        display: inline-block;
        width: 100%;
        padding: 20px;
        color: $black;
        @include media("<=mobile-landscape") {
            padding: 10px;
        }
        h4 {
            margin-bottom: 10px;
            @include media("<=mobile-landscape") {
                font-size: 14px;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
}
